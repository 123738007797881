import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import foursafe_logo_dark from '../../img/logo-new2.svg';
import urbii_logo from '../../img/urbii-logo.svg';
import cittua_logo from '../../img/cittua-logo.svg';
import OperationalStageCard from './operational_stage';

import styles from '../../css/style.scss';

export default function TopBar(props) {
    const { t } = useTranslation();
    const title = props.title ? props.title : t('title');

    return (
        <Row className="justify-content-between align-items-center mb-3 p-0" style={{background: '#2f3636'}}>
            <Col md={12} className='p-0 pb-3 w-100'>
                <hr className='my-0' />
            </Col>
            <Col md={'auto'} className='pl-5'>
                <div style={{marginLeft: '20px', fontSize: '1.8vw'}}>
                    {title}
                </div>
            </Col>
            <Col md={3} className='w-100 h-100'>
                <OperationalStageCard />
            </Col>
            <Col md={'auto'} style={{textAlign: 'right'}} className='mr-5 pr-5'>
                <img src={cittua_logo} style={{width: '6vw'}} />
            </Col>
            <Col md={12} className='p-0 pt-3 w-100'>
                <hr className='my-0' />
            </Col>
        </Row>
    );
}
/**
 * Foursafe API's Class
 */

import {SERVICE_URL} from './config';

export default class FoursafeAPI {

    constructor(app_key, app_id) {
        this.app_key = app_key;
        this.app_id = app_id;
        this.auth_token = null;
        this.retries = 0;
    }

    _constructHeaders() {
        const headers = new Headers();
        headers.set('Authorization', `Token ${this.auth_token}`);
        headers.set('Content-Type', 'application/json');
        headers.set('Access-Control-Allow-Origin', '*');

        return headers;
    }

    async init() {
        this.auth_token = await this.getAppToken();
        this.retries = 0;
    }

    async getAppToken() {
        let response = await fetch(`${SERVICE_URL}/api/credentials/get_token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                app_id: this.app_id,
                app_key: this.app_key
            })
        });
        let data = await response.text();
        return data.split('\"')[1];
    }

    fetch(method,url,options) {
      return fetch(url, {
        method: method.toUpperCase(),
        headers: this._constructHeaders(),
        ...options,
      }).then(async (response) => {
        if (response.status === 401 && this.retries <= 10) {
          this.retries++;
          console.log('Retrying ', this.retries, '...');
          this.auth_token = await this.getAppToken();
          return this.fetch(method,url,options);  
        } else if (response.status === 200) {
          this.retries = 0;
          return response.json();
        } else {
          this.retries = 0;
          return Promise.reject('Too many retries');
        }
      }).catch(error => {
        console.log('There has been a problem with your fetch operation: ' + error);
      })
    }


}
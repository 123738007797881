import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Line } from 'react-chartjs-2';
import { getMobilityCityData } from '../utils/apis';
import { getChart } from '../utils/apis';
import { RELOAD_TIME, TOASTS_CONFIG } from '../utils/config';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { toast } from 'react-toastify';
import { MOBILITY_CITY_DATA } from '../../data/petropolis/mock_city_data';
import { calculateIndicatorsRisk, getVariationText } from '../utils/risks';

import styles from '../../css/style.scss';


class TrafficGraph extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showMenu: false,
            isLoading: true,
            timestamp: null,
            data: [],
        };
    }

    componentDidMount() {
        //Recarregar no intervalo definido (em milissegundos)
        this.interval = setInterval(() => {
            //this.setState({ time: Date.now() });
            this.getData();
            console.log('Graph reloaded at ', new Date().toLocaleString());
        }, RELOAD_TIME);
        this.getData();
        document.addEventListener("click", this.handleClickOutside);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        document.removeEventListener("click", this.handleClickOutside);
    }

    toggleMenu = event => {
        event.preventDefault();
        this.setState({
            showMenu: !this.state.showMenu,
        });
    }

    handleClickOutside = event => {
        event.preventDefault();
        if (this.dropdownMenu && !this.dropdownMenu.contains(event.target)){
            this.setState({
                showMenu: false
            });
        }
    };


    async getData() {
        this.setState({
            isLoading: true
        });
        const data = await getChart('https://dev.foursafe.com/api/urbii/get_chart')
        const datetime = data.x_label
        let x_label = []
        const timeseries_4 = data.datasets[3].data
        const timestamp = data.timestamp
        datetime.forEach(element => {
            const hour = element.slice(11,16)
            x_label.push(hour)
        })
           
    
        const dataset_linha_cota_transbordamento = data.datasets[4].data;
        const dataset_linha_cota_80 = data.datasets[5].data;

        const dataArray = [timeseries_4,dataset_linha_cota_transbordamento,dataset_linha_cota_80];
        const labelArray = ['Nivel do Rio', 'Cota de Transbordamento', 'Cota de 80%'];
        const axisIDArray = ['level', 'level', 'level'];
        const lineColor = [
        	{
        		backgroundColor: 'rgba(147,147,147,0.4)', 
        		borderColor: 'rgba(147,147,147,1)',
        		pointBorderColor: 'rgba(147,147,147,1)',
        		pointHoverBackgroundColor: 'rgba(147,147,147,1)',
        		pointHoverBorderColor: 'rgba(255,255,255,1)',
        	},
        	{
        		backgroundColor: styles.very_high, 
        		borderColor: styles.very_high,
        		pointBorderColor: styles.very_high,
        		pointHoverBackgroundColor: styles.very_high,
        		pointHoverBorderColor: styles.very_high,
        	},
        	{
        		backgroundColor: styles.medium, 
        		borderColor: styles.medium,
        		pointBorderColor: styles.medium,
        		pointHoverBackgroundColor: styles.medium,
        		pointHoverBorderColor: 'rgba(255,255,255,1)',
        	}
        ]

        const datasets = dataArray.map((data, index) => {
        	return {
        		label: labelArray[index],
		        fill: false,
            lineTension: 0.5,
            backgroundColor: lineColor[index].backgroundColor,
            borderColor: lineColor[index].borderColor,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: lineColor[index].pointBorderColor,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: lineColor[index].pointHoverBackgroundColor,
            pointHoverBorderColor: lineColor[index].pointHoverBorderColor,
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            yAxisID: axisIDArray[index],
            data: data,
        	}
        })
        
        this.setState({
        		data: {
        			labels: x_label,
        			datasets: datasets.map(item => item)
        		},
            timestamp,
            isLoading: false
        });
    }

    renderDropDown() {
        const { t } = this.props;
        return (
            <Row className="" style={{position: 'absolute', top: '9%', right: '5%', backgroundColor: '#1f2727', borderRadius: '5px', minWidth: '100px', boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)', zIndex: 1, fontSize: '1.3rem'}}>
                <Col>
                    <Row>
                        <Col md={12} className="dropdown-option p-4 cursor-pointer dot-menu-item" onClick={e => this.downloadChart()}>{t('dashboard.dropdown.export_image')}</Col>
                    </Row>
                    <Row>
                        <Col md={12} className='p-0'><hr className='my-0' /></Col>
                    </Row>
                    <Row>
                        <Col md={12} className="dropdown-option p-4 cursor-pointer dot-menu-item" onClick={e => this.copyChart(e)}>{t('dashboard.dropdown.copy_image')}</Col>
                    </Row>
                    {/* <Row>
                        <Col md={12} className='p-0'><hr className='my-0' /></Col>
                    </Row>
                   	<Row>
                        <Col md={12} className="dropdown-option p-4 cursor-pointer dot-menu-item" onClick={this.copyMetrics}>{t('dashboard.dropdown.copy_indicators')}</Col>
                    </Row>
                    <Row>
                        <Col md={12} className='p-0'><hr className='my-0' /></Col>
                    </Row>
                    <Row>
                        <Col md={12} className="dropdown-option p-4 cursor-pointer dot-menu-item" onClick={e => e}>Trocar estágio operacional</Col>
                    </Row> */}
                </Col>
            </Row>
        )
    }

    copyMetrics = () => {
        const { t } = this.props;
        let slowestVelocityData = this.props.indicators.slowestVelocityData;
        let impactedVehiclesData = this.props.indicators.impactedVehiclesData;
        let congestedKmData = this.props.indicators.congestedKmData;
        let densityData = this.props.indicators.densityData;

        if (this.state.isLoading) {
            toast.error(t('messages.no_data_copy'), TOASTS_CONFIG);
            return
        }

        let timestamp = this.props.indicators.last_modified;
        let date = new Date(timestamp*1000).toLocaleDateString();
        let time = new Date(timestamp*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});

        let slowestVelocityRisk, impactedVehiclesRisk, congestedKmRisk, text_risk='no';

        if (slowestVelocityData.slowestVelocity && impactedVehiclesData.numImpactedVehicles && congestedKmData.numCongestedKmData) {
            slowestVelocityRisk = calculateIndicatorsRisk(slowestVelocityData.slowestVelocity, slowestVelocityData.avgSlowestVelocity, true);
            impactedVehiclesRisk = calculateIndicatorsRisk(impactedVehiclesData.numImpactedVehicles, impactedVehiclesData.avgImpactedVehicles);
            congestedKmRisk = calculateIndicatorsRisk(congestedKmData.numCongestedKmData, congestedKmData.avgCongestedKmData);
    
            if (slowestVelocityRisk=='very_high' || impactedVehiclesRisk=='very_high' || congestedKmRisk=='very_high'){
                text_risk = 'very_high';
            } else if (slowestVelocityRisk=='high' || impactedVehiclesRisk=='high' || congestedKmRisk=='high'){
                text_risk = 'high';
            } else if (slowestVelocityRisk=='medium' || impactedVehiclesRisk=='medium' || congestedKmRisk=='medium'){
                text_risk = 'medium';
            } else if (slowestVelocityRisk=='low' || impactedVehiclesRisk=='low' || congestedKmRisk=='low'){
                text_risk = 'low';
            }
        }

        let text = '';
        if (text_risk=='no'){
            text = t('overview.indicators.report.no_alert');
        } else {
            text = t('overview.indicators.report.template', {speed: Math.ceil(slowestVelocityData.slowestVelocity), traffic: Math.ceil(congestedKmData.numCongestedKmData), density: Math.ceil(densityData.numDensityData), alert: t(`overview.indicators.report.${text_risk}_alert`), slowest_name: slowestVelocityData.slowestName, traffic_variation: getVariationText(congestedKmData.variationCongestedKmData), density_variation: getVariationText(densityData.variationDensityData), speed_variation: getVariationText(slowestVelocityData.avgSlowestVariation, 'speed')});
        }
        text += `\n${t('dashboard.source')}`;
        text += `\n${t('map.subtitle', {date: date, time: time})}`;

        let blob = new Blob([text], {type: 'text/plain'});
        const item = new ClipboardItem({'text/plain': blob});
        navigator.clipboard.write([item]);

        toast.success(t('messages.copy_indicators_success'), TOASTS_CONFIG);
    }

    downloadChart = () => {
        let timestamp = new Date();
        let date = new Date(timestamp).toLocaleDateString().replaceAll('/','-');
        let time = new Date(timestamp).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}).replaceAll(':','-');
        let div_element = document.getElementById("traffic-graph");
        const options = {
            backgroundColor :'#1a2323'
        }
        html2canvas(div_element, options).then(canvas => {
            canvas.toBlob(blob => {
                saveAs(blob, "traffic_graph_" + date + '_' + time + ".png");
            });
        });
    };

    copyChart = (event) => {
        const { t } = this.props;
        let div_element = document.getElementById("traffic-graph");
        const options = {
            backgroundColor :'#1a2323'
        }
        html2canvas(div_element, options).then(canvas => {
            canvas.toBlob(blob => {
                const item = new ClipboardItem({"image/png": blob});
                navigator.clipboard.write([item]); 
            });
        });
        this.toggleMenu(event);
        toast.success(t('messages.copy_graph_success'), TOASTS_CONFIG);
    };

    exportMode = () => {
        if (this.props.exportMode && !this.state.isLoading) {
            const div_element = document.getElementById("traffic-graph");
            const options = {
                backgroundColor :'#1a2323'
            }
            html2canvas(div_element, options).then(canvas => {
                const id = 'data-graph';
                let element = document.getElementById(id);
                if (!element) {
                    element = document.createElement('GRAPH');
                    document.body.appendChild(element); 
                    element.setAttribute("id", id);
                }
                let att = document.createAttribute("dataURL"); // Create a "data" attribute
                att.value = canvas.toDataURL('image/png'); // Set the value of the data attribute
                element.setAttributeNode(att);
            });
        }
    }

    render() {
        const { t, i18n } = this.props;
        let timestamp = new Date(this.state.timestamp*1000);
        let date = timestamp.toLocaleDateString();
        let time = timestamp.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
        if (this.props.exportMode) { // Em caso de modo de exportação (para bot do Telegram)
            i18n.changeLanguage('pt'); // Força mudança de idioma
            date = new Intl.DateTimeFormat('pt-BR').format(timestamp); // Força mudança de locale para formatação de data em pt-BR
        }
        const chartRef = React.createRef(null);
        let isLoading = this.state.isLoading;

        const options = {
            legend: {
                position: 'bottom',
                labels: {
                    boxWidth: 12
                }
            },
            title: {
                display: true,
                position: 'bottom',
                text: "Gráfico de Transbordamento"
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [
                    {
                        id: "rain",
                        scaleLabel: {
                            display: true,
                            labelString: "Chuva (milímetros)"
                        },
                        ticks: {
                            suggestedMin: 10,
                            maxTicksLimit: 6
                        }
                    },
                    {
                        id: "level",
                        scaleLabel: {
                            display: true,
                            labelString: "Nível do Rio (metros)"
                        },
                        ticks: {
                            suggestedMin: 0,
                            maxTicksLimit: 4
                        },
                        position: 'right'
                    }
                ]
            },
            animation: {
                onComplete: this.exportMode
            }
        }

        return (
            <Col md className='h-100 w-100'>
                <Row className='h-100' id="traffic-graph">
                    <Col md className='h-100 w-100'>
                        <Row className='align-items-center justify-content-between py-2' style={{color: 'White'}}>
                            <Col md={8} style={{padding: '10px 0 0 0', textAlign: 'right', fontSize: '1.3rem', fontWeight: 'bold'}}>
                                {t('overview.graph.title')} |&nbsp;
                            </Col>
                            <Col md={3} style={{padding: '10px 0 0 0', textAlign: 'left', fontSize: '1.2rem'}}>
                                {
                                    isLoading ?
                                        <SkeletonTheme color="#4c5959" highlightColor="#374141">
                                            <p>
                                                <Skeleton height={20}/>
                                            </p>
                                        </SkeletonTheme>
                                    :
                                        t('overview.graph.subtitle', {date: date, time: time})
                                }
                            </Col>
                            <Col md={1} className="cursor-pointer" style={{textAlign: 'center'}}  ref={(element) => {this.dropdownMenu = element}} onClick={this.toggleMenu}>
                                <FontAwesomeIcon icon={faEllipsisV} size="xs" />
                            </Col>
                        </Row>
                        <Row className='align-items-center'>
                            <Col md className='p-0'>
                                <hr className='mb-0' />
                            </Col>
                        </Row>
                        <Row className='h-75'>
                            <Col className='mt-5' style={{height: '27vh'}}>
                                {isLoading ? 
                                    <SkeletonTheme color="#4c5959" highlightColor="#374141">
                                        <p>
                                            <Skeleton height={180}/>
                                        </p>
                                    </SkeletonTheme>
                                :
                                    this.state.data && <Line data={this.state.data} options={options} ref={chartRef} />
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {this.state.showMenu ? this.renderDropDown() : null}
            </Col>
        );
    }
}

function mapStateToProps({indicators}) {
	return {
		indicators: indicators
	}
}

export default connect(mapStateToProps)(withTranslation()(TrafficGraph));
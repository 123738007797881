import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBus, faCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const SkeletonStreet = () => {
    return (
        <div className="dashboard-street__wrap">
            <div className="cursor-pointer d-flex flex-row align-content-around align-content-center">
                <SkeletonTheme color="#4c5959" highlightColor="#374141">
                    <p>
                        <Skeleton width={'50vw'} height={40}/>
                    </p>
                </SkeletonTheme>
            </div>
        </div>
    );
  };

  export default SkeletonStreet;
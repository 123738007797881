import i18n from 'i18next';

export const FLOOD_CLASS = {
    "Alagamento": 'flooding',
    "Bolsão d'água": 'pocket',
    "Lâmina d'água": 'blade',
    "Sem evento": 'no_event'
}

const FLOOD_RISK_PROBA = {
    'risk_very_high': 95,
    'risk_high': 87,
    'risk_medium': 80,
    'risk_low': 70
}

export const getFloodRisk = (risk_proba) => {
    risk_proba = risk_proba * 100;
    if (risk_proba >= FLOOD_RISK_PROBA.risk_very_high) {
        return 'very_high'
    }
    else if (risk_proba >= FLOOD_RISK_PROBA.risk_high) {
        return 'high'
    }
    else if (risk_proba >= FLOOD_RISK_PROBA.risk_medium) {
        return 'medium'
    }
    else {
        return 'low'
    }
}

const INDICATORS_RISK = {
    'risk_very_high': 75,
    'risk_high': 50,
    'risk_medium': 25
}

export const calculateIndicatorsRisk = (value, average, inverse=false) => {
    let perc = value/average - 1;
    perc = inverse ? -perc : perc;
    perc = perc * 100;
    if (perc > INDICATORS_RISK.risk_very_high) {
        return 'very_high'
    }
    else if (perc > INDICATORS_RISK.risk_high) {
        return 'high'
    }
    else if (perc > INDICATORS_RISK.risk_medium) {
        return 'medium'
    }
    else {
        return 'low'
    }
}

export const calculateBusIndicatorsRisk = (perc, inverse=false) => {
    perc = inverse ? -perc : perc;
    if (perc > INDICATORS_RISK.risk_very_high) {
        return 'very_high'
    }
    else if (perc > INDICATORS_RISK.risk_high) {
        return 'high'
    }
    else if (perc > INDICATORS_RISK.risk_medium) {
        return 'medium'
    }
    else {
        return 'low'
    }
}

export function getVariationText(value, type=false) {
    let text = '';
    try {
        let text_value = String(parseInt(Math.abs(value)));
        if (!type) {
            if (value > 0) {
                text = i18n.t('variations.above_average', {value: text_value});
            } else if (value < 0) {
                text = i18n.t('variations.lower_average', {value: text_value});
            } else {
                text = i18n.t('variations.average');
            }
        } else if (type=='speed') {
            if (value > 0) {
                text = i18n.t('variations.slower', {value: text_value});
            } else if (value < 0) {
                text = i18n.t('variations.faster', {value: text_value});
            } else {
                text = i18n.t('variations.expected');
            }
        }
        return `(${text})`
    }
    catch(err) {
        return text
    }
}
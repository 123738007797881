import React from 'react';
import { getIndicator } from '../utils/apis'
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { RELOAD_TIME, TOASTS_CONFIG } from '../utils/config';
import styles from '../../css/style.scss';
import { useEffect, useState, useRef  } from 'react';

export default function OperationalStageCard(props) {
    
    const { t } = useTranslation();

    const [data, setData] = useState({})
    const [timestamp, setTimestamp] = useState(null)

        useEffect(() => {

            async function getData() {
                let response = await getIndicator('https://dev.foursafe.com/api/urbii/indicators/stage');
                
                setData(response)
                
                let time = new Date(response.data.timestamp*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
                let date = new Date(response.data.timestamp*1000).toLocaleDateString();
                setTimestamp(t('overview.indicators.operational_stage.time', {time: time, date: date}))
            }

            const interval = setInterval(() => {
                getData()
                
            }, RELOAD_TIME);
            getData()
            return () => clearInterval(interval)
        }, [])

        return (
            
            Object.keys(data).length !== 0 && (
            <Row className="align-items-center py-1 h-100" style={{borderRadius: '10px', background: data.data.cor}}>
                <Col md={6} style={{fontWeight: 'bold'}}>
                    <span style={{fontSize: '1.5vw'}}> {data.data.stage} </span>
                </Col>
                <Col md={6}>
                    <Row>
                        <Col md={12} style={{fontSize: '0.6em', textAlign: 'right'}}>
                            {t('overview.indicators.operational_stage.title')}
                        </Col>
                        <Col md={12} style={{fontSize: '0.5em', textAlign: 'right'}}>
                            {timestamp}
                        </Col>
                    </Row>
                </Col>
            </Row>
            )
        );
}
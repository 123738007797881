import React from 'react'

import img from './../../img/logo-new2.svg'

class Notfound extends React.Component {
    render() {
        return (
            <div className="not-found-page">
                <div className="not-found__message">
                    <h5>404</h5>
                    <h6>Ops! Página não encontrada. <br /><br /><a href="/">Retorne para página inicial! ;)</a></h6>
                    <img src={img} alt="" width="200px" />
                </div>
            </div>
        );
    }
}

export default Notfound
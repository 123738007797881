/** Configuration File */

export const SERVICE_URL = process.env.SERVICE_URL;
export const FOURSAFE_APP_KEY = process.env.FOURSAFE_APP_KEY;
export const FOURSAFE_APP_ID = process.env.FOURSAFE_APP_ID;

export const RELOAD_TIME = 3*60*1000; // in miliseconds

export const DEFAULT_BOUNDS = [ 
    [-22.2041840864264017, -43.0187955163000026],
    [-22.5721828970309666, -43.3398614664334971]
]

export const RESET_VIEW_CMD = '$reset-view';

export const CAMERA_ZOOM_LEVEL = 13;

export const TILELAYER_TEMPLATE = {
    url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
    attribution: 'Urbii, &copy; <a href="https://www.wiiglo.com" target=_blank>Wiiglo</a>, &copy; <a href="https://stadiamaps.com/" target=_blank>Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/" target=_blank>OpenMapTiles</a> &copy; <a href="http://openstreetmap.org" target=_blank>OpenStreetMap</a> contributors'
}

export const TILELAYER_TEMPLATE_VIDEOWALL = {
    url: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
    attribution: 'Urbii, &copy; <a href="https://www.wiiglo.com" target=_blank>Wiiglo</a>, &copy; <a href="https://www.openstreetmap.org/copyright" target=_blank>OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions" target=_blank>CARTO</a>'
}

export const TOASTS_CONFIG = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined
}


export const GA_CODE = 'UA-114777381-7'
export const CITY_CODE = "3304557";
export const URL_GIF = `${SERVICE_URL}/api/urbii/get/radarImage/10`
import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { RELOAD_TIME, TOASTS_CONFIG } from '../utils/config';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { getMobilityCityData } from '../utils/apis';
import { calculateIndicatorsRisk, getVariationText } from '../utils/risks';
import { toast } from 'react-toastify';
import { MOBILITY_CITY_DATA } from '../../data/petropolis/mock_city_data';
import OperationalStageCard from './operational_stage';
import { getIndicator } from '../utils/apis';

import styles from '../../css/style.scss';
import { setIndicators } from '../actions/indicators';

class IndicatorsWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            timestamp: null,
            last_modified: null,
            operationalStageData: null,
            indicator24h: null,
            indicator72h: null,
            indicator1h: null,
            indicator4h: null,
            //copyIndicatorsCmd: this.props.copyIndicatorsCmd
        };
    }

    componentDidMount() {
        //Recarregar no intervalo definido (em milissegundos)
        this.interval = setInterval(() => {
            this.setState({ time: Date.now() });
            this.getData();
        }, RELOAD_TIME);
        this.getData();
    }

    async getData() {
        this.setState({
            isLoading: true
        });
        const data = await getIndicator('https://dev.foursafe.com/api/urbii/indicators/get/0')
        const indicators_1h = data['1h']
        const indicators_4h = data['4h']
        const indicators_24h = data['24h']
        const indicators_72h = data['72h'] 
        let mobility_city_data = MOBILITY_CITY_DATA;
        this.props.dispatch(setIndicators({
            indicator24h: {
                accumulated: indicators_24h['accumulated'],
                risk: indicators_24h['risk'],
                name: `Estação: ${indicators_24h['name']}`,
                trend: indicators_24h['trend'],
            },
            indicator72h: {
                accumulated: indicators_72h['accumulated'],
                risk: indicators_72h['risk'],
                name: `Estação: ${indicators_72h['name']}`,
                trend: indicators_72h['trend'],

            },
            indicator1h: {
                accumulated: indicators_1h['accumulated'],
                risk: indicators_1h['risk'],
                name: `Estação: ${indicators_1h['name']}`,
                trend: indicators_1h['trend'],
            },
            indicator4h: {
                accumulated: indicators_4h['accumulated'],
                risk: indicators_4h['risk'],
                name: `Estação: ${indicators_4h['name']}`,
                trend: indicators_4h['trend'],
            },
            timestamp: mobility_city_data.timestamp,
            last_modified: mobility_city_data.last_modified,
        }))
        this.setState({
        	isLoading: false
        })
    }

    render() {
    	let { operationalStageData, indicator24h, indicator72h, indicator1h, indicator4h } = this.props;
        let isLoading = this.state.isLoading;
        if (!operationalStageData) {
            operationalStageData = {'cor': '#4c5959', 'estagio': 'Indisponível', 'inicio': null}
        }

        return (
            <Col>
                {/* <Row className='mt-4 h-20'>
                    <Col className='w-100 h-100'>
                        {
                            (isLoading || (operationalStageData) != null) ?
                            <SkeletonIndicatorCard />
                            :
                            <OperationalStageCard data={operationalStageData}/>
                        }
                    </Col>
                </Row> */}
                <Row className='mt-4 h-30'>
                    <Col className='w-100 mh-100'>
                    	<Row className='h-100'>
                  		{
                        isLoading ?
                        <SkeletonIndicatorCard />
                        :
                        this.props.indicators.indicator1h && <IndicatorCard type='congested_km' data={this.props.indicators.indicator1h}/>
                      }
                    	</Row>
                    </Col>
                    <Col className='w-100 pl-5 mh-100'>
                    	<Row className='h-100'>
                  		{
                        isLoading ?
                        <SkeletonIndicatorCard />
                        :
                        this.props.indicators.indicator4h && <IndicatorCard type='density' data={this.props.indicators.indicator4h}/>
                      }
                    	</Row>
                    </Col>
                </Row>
                <Row className='mt-4 h-30'>
                	<Col className='w-100 mh-100'>
                		<Row className='h-100'>
                		{
                			isLoading ?
                			<SkeletonIndicatorCard/>
                			:
                			this.props.indicators.indicator24h && <IndicatorCard type='slowest_velocity' data={this.props.indicators.indicator24h}/>
                		}
                		</Row>
                	</Col>
                	<Col className='w-100 pl-5 mh-100'>
                		<Row className='h-100'>
                		{
                			isLoading ?
                			<SkeletonIndicatorCard/>
                			:
                			this.props.indicators.indicator72h && <IndicatorCard type='impacted_vehicles' data={this.props.indicators.indicator72h}/>
                		}
                		</Row>
                	</Col>
                </Row>
            </Col>
        )
    }
}

function SkeletonIndicatorCard(){
    return (
        <SkeletonTheme color="#4c5959" highlightColor="#374141">
            <p>
                <Skeleton height={100}/>
            </p>
        </SkeletonTheme>
    )
}

function IndicatorCard(props) {
	const { t } = useTranslation();
	let value = '';
    let average = '';
    let name = '';
    let riskColor = '';
    let risk = ''
    let title = '';
    let tendency = '';

  switch(props.type) {
  	case 'slowest_velocity': 
  		value = props.data.accumulated;
  		average = props.data.trend;
  		name = props.data.name;
        risk = props.data.risk
  		title = 'Maior Acc em 24h';
  		riskColor = styles[`risk_${risk}`];
			if (!value) {
			  riskColor = styles['risk_low'];
			}
		value = Math.ceil(value*10)/10;

        if (Math.abs(average) == 0.0){
            tendency = 'Tendência: -';
        }else if (average > 0){
            tendency = 'Tendência: ▲';
        }else {
            tendency = 'Tendência: ▼';
        }

		
		break;
	case 'impacted_vehicles': 
        value = props.data.accumulated;
        average = props.data.trend;
        name = props.data.name;
        risk = props.data.risk
        title = 'Maior Acc em 72h';
        riskColor = styles[`risk_${risk}`];
        if (!value) {
            riskColor = styles['risk_low'];
        }
        value = Math.ceil(value*10)/10;

        if (Math.abs(average) == 0.0){
            tendency = 'Tendência: -';
        }else if (average > 0){
            tendency = 'Tendência: ▲';
        }else {
            tendency = 'Tendência: ▼';
        }
        break;
		case 'congested_km': 
        value = props.data.accumulated;
            average = props.data.trend;
            name = props.data.name;
            risk = props.data.risk
            title = 'Maior Acc em 1h';
            riskColor = styles[`risk_${risk}`];
            if (!value) {
                riskColor = styles['risk_low'];
            }
            value = Math.ceil(value*10)/10;

            if (Math.abs(average) == 0.0){
                tendency = 'Tendência: -';
            }else if (average > 0){
                tendency = 'Tendência: ▲';
            }else {
                tendency = 'Tendência: ▼';
            }
		  break;
		case 'density': 
            value = props.data.accumulated;
            average = props.data.trend;
            name = props.data.name;
            risk = props.data.risk
            title = 'Maior Acc em 4h';
            riskColor = styles[`risk_${risk}`];
            if (!value) {
                riskColor = styles['risk_low'];
            }
            value = Math.ceil(value*10)/10;

            if (Math.abs(average) == 0.0){
                tendency = 'Tendência: -';
            }else if (average > 0){
                tendency = 'Tendência: ▲';
            }else {
                tendency = 'Tendência: ▼';
            }
            break;	
		default:
			return null;
  }

	return (
		<Col className='align-items-center w-100 mh-100 py-3' style={{borderRadius: '10px', borderStyle: 'solid', borderColor: riskColor}}>
			<Row>
				<Col className='col-xl-6' style={{fontWeight: 'bold', color: riskColor, fontSize: '3rem'}}>
					{value ? (props.type !== 'congested_km' ? t('overview.indicators.slowest_velocity.unit', {value: value}) : value+ ' mm') : '-'}
				</Col>
				<Col className='col-xl-6'>
					<p style={{borderRadius: '1.5rem', background: '#333d3d', fontSize: '1.5rem', padding: '0.5rem', textAlign: 'center'}}>{title}</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<p style={{fontSize: '2rem'}}>{name}</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<p style={{fontSize: '1.4rem'}}>{tendency}</p>
				</Col>
			</Row>
		</Col>
	)
}

function SlowestVelocityCard(props) {
    const { t } = useTranslation();
    let value = props.data.accumulated;
    let average = props.data.trend;
    let slowest_name = props.data.name;
    let riskColor = styles['risk_' + calculateIndicatorsRisk(value, average, true)];
    if (!value) {
        riskColor = styles['risk_low'];
    }
    value = Math.ceil(value*10)/10;
    average = Math.ceil(average);

    //Fake Data
    riskColor = styles['risk_high'];

    return (
        <Row className="align-items-center p-3 h-100" style={{borderRadius: '10px', borderStyle: 'solid', borderColor: riskColor}}>
            <Col md={6} className="pr-0" style={{fontWeight: 'bold', color: riskColor, fontSize: '1.8vw'}}>
                {value ? t('overview.indicators.slowest_velocity.unit', {value: value}) : '-'}
            </Col>
            <Col md={'auto'} style={{borderRadius: '10px', background: '#333d3d', fontSize: '0.8vw', top: '-5px', 'right': '-10px'}} className='py-2 px-3'>
                {t('overview.indicators.slowest_velocity.title')}
            </Col>
            <Col md={12} style={{fontSize: '1.1vw'}}>
                {slowest_name}
            </Col>
            <Col md={12} style={{fontSize: '0.4em'}} className='pt-0'>
                {/* {average ? t('overview.indicators.slowest_velocity.average', {value: average}) : (t('overview.graph.legends.avg_last_weeks')+': -')} */}
                Tendência: ▲
            </Col>
        </Row>
    );
}

function ImpactedVehiclesCard(props) {
    const { t } = useTranslation();
    let value = props.data.numImpactedVehicles;
    let average = props.data.avgImpactedVehicles;
    // let riskColor = styles['risk_' + calculateIndicatorsRisk(value, average)];
    let riskColor = styles['risk_very_high']
    value = Math.ceil(value);
    average = Math.ceil(average);
    if (!value) {
        value = '0';
    }

    return (
        <Row className="align-items-center p-3 h-100" style={{borderRadius: '10px', borderStyle: 'solid', borderColor: riskColor}}>
            <Col md={6} className="pr-0" style={{fontWeight: 'bold', color: riskColor, fontSize: '1.8vw'}}>
                {/* {value} */}
                80.1 mm
            </Col>
            <Col md={'auto'} style={{borderRadius: '10px', background: '#333d3d', fontSize: '0.8vw', top: '-5px', 'right': '-10px'}} className='py-2 px-3'>
                {t('overview.indicators.impacted_vehicles.title') && 'Maior Acc em 72h'}
            </Col>
            <Col md={12} style={{fontSize: '1.1vw'}}>
                {t('overview.indicators.impacted_vehicles.unit') && 'Estação: Quitandinha'}
            </Col>
            <Col md={12} style={{fontSize: '0.4em'}} className='pt-0'>
                {t('overview.indicators.impacted_vehicles.average', {value: '▲▲'})}
            </Col>
        </Row>
    );
}

function CongestedKmCard(props) {
    const { t } = useTranslation();
    let value = props.data.numIndicator1h;
    let average = props.data.avgIndicator1h;
    let riskColor = styles['risk_' + calculateIndicatorsRisk(value, average)];
    value = Math.ceil(value);
    average = Math.ceil(average);
    if (!value) {
        value = '0';
    }

    return (
        <Row className="align-items-center p-3 h-100" style={{borderRadius: '10px', borderStyle: 'solid', borderColor: riskColor}}>
            <Col md={5} className="pr-0" style={{fontWeight: 'bold', color: riskColor, fontSize: '1.8vw'}}>
                {t('overview.indicators.congested_km.unit', {value: value})}
            </Col>
            <Col md={'auto'} style={{borderRadius: '10px', background: '#333d3d', fontSize: '0.8vw', top: '-5px', 'right': '-10px'}} className='py-2 px-3'>
                {t('overview.indicators.congested_km.title')}
            </Col>
            <Col md={12} style={{fontSize: '1.1vw'}}>
                {t('overview.indicators.congested_km.subtitle')}
            </Col>
            <Col md={12} style={{fontSize: '0.4em'}} className='pt-0'>
                {/* {average ? t('overview.indicators.congested_km.average', {value: average}) : (t('overview.graph.legends.avg_last_weeks')+': -')} */}
                Tendência: ▼
            </Col>
        </Row>
    );
}

function DensityCard(props) {
    const { t } = useTranslation();
    let value = props.data.numIndicator4h;
    let average = props.data.avgIndicator4h;
    let risk_class = props.data.classIndicator4h;
    let riskColor = styles['indicators_' + risk_class];
    let name = props.data.name;
    value = Math.ceil(value*10)/10;
    average = Math.ceil(average*10)/10;
    if (!value) {
        value = '0';
    }

    riskColor = styles['risk_medium'];

    return (
        <Row className="align-items-center p-3 h-100" style={{borderRadius: '10px', borderStyle: 'solid', borderColor: riskColor}}>
            <Col md={5} className="pr-0" style={{fontWeight: 'bold', color: riskColor, fontSize: '1.8vw'}}>
                {value ? t('overview.indicators.slowest_velocity.unit', {value: value}) : '-'}
            </Col>
            <Col md={'auto'} style={{borderRadius: '10px', background: '#333d3d', fontSize: '0.8vw', top: '-5px', 'right': '-10px'}} className='py-2 px-3'>
                {t('overview.indicators.density.title')}
            </Col>
            <Col md={12} style={{fontSize: '1.1vw'}}>
                {name}
                {/* {t('overview.indicators.density.unit')} */}
            </Col>
            <Col md={12} style={{fontSize: '0.4em'}} className='pt-0'>
                {/* {t('overview.indicators.slowest_velocity.average', {value: average})} */}
                Tendência: ▼▼
            </Col>
        </Row>
    );
}

function mapStateToProps({indicators}) {
	return {
		indicators
	}
}

export default connect(mapStateToProps)(withTranslation()(IndicatorsWrapper));
import { SET_INDICATORS } from '../actions/indicators';

export default function indicators(state={}, action) {
	switch(action.type) {
		case SET_INDICATORS: 
			return {
				...state,
				...action.indicators
			}
		default: 
			return state
	}
}
import React, { Suspense, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import MapWrap from './map';
import Dashboard from './dashboard';
import TopBar from './topbar';
import TrafficGraph from './traffic_graph';
import IndicatorsWrapper from './indicators';
import { Container, Row, Col, Toast } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { RESET_VIEW_CMD, GA_CODE } from '../utils/config';
import { ToastContainer } from 'react-toastify';
import { RELOAD_TIME } from '../utils/config';
import ReactGA from 'react-ga';
//import hash from 'object-hash';



class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            segment_id: RESET_VIEW_CMD,
            cameras_layer: [],
            //copyIndicatorsCmd: false,
            vision: 'microbacias'
        }
    }

    goToPoint(segment_id) {
        this.setState({
            segment_id: segment_id
        });
    }

    setCameraLayer(cameras_layer) {
        this.setState({
            cameras_layer: cameras_layer
        });
    }

    componentDidMount() {
        ReactGA.initialize(GA_CODE)
        ReactGA.pageview(window.location.pathname)
    }

    render() {
        const { t } = this.props;
        let style = this.props.style;
        let segment_id = this.state.segment_id;
        let cameras_layer = this.state.cameras_layer;
        let vision = this.state.vision;

        return (
            <Container fluid='true' style={{background: '#1A2323', color: '#ffffff'}} className='px-0'>
                <Suspense fallback={(<Skeleton />)}>
                    <TopBar />
                </Suspense>
                <Row style={{height:'90vh'}} className='px-5 pb-3'>
                    <Col md={5} style={{borderRadius: '10px', background: '#2f3636'}} className='h-100'>
                        <Row className='align-items-center py-2'>
                            <Col md style={{padding: '10px 0 0 0', textAlign: 'center', fontSize: '1.8rem', fontWeight: 'bold'}}>
                                {t('overview.title')}
                            </Col>
                        </Row>
                        <Row className='align-items-center'>
                            <Col md className='p-0'>
                                <hr />
                            </Col>
                        </Row>
                        
                        <Row className='m-3' style={{borderRadius: '10px', background: '#1A2323', height: '45%'}}>
                            <Suspense fallback={(<Skeleton />)}>
                                <TrafficGraph/>
                            </Suspense>
                        </Row>
                        <Row className='m-3' style={{height: '30%'}}>
                            <Suspense fallback={(<Skeleton />)}>
                                <IndicatorsWrapper/>
                            </Suspense>
                        </Row>
                    </Col>
                    <Col md style={{borderRadius: '10px', background: '#2f3636'}} className='mx-4 h-100'>
                        <Suspense fallback={(<Skeleton />)}>
                            <MapWrap segment_id={segment_id} style={style} cameras_layer={cameras_layer} parentCallback={(e) => this.setState({vision: e})} />
                        </Suspense>
                        <Row className='' style={{height: '55%'}}>
                            <Col>
                                <Suspense fallback={(<Skeleton />)}>
                                    <Dashboard parentCallback={(e) => this.goToPoint(e)} style={style} camerasLayerCallback={(e) => this.setCameraLayer(e)} vision={vision}/>
                                </Suspense>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <ToastContainer />
            </Container>
        );
    }
}

export default withTranslation()(App);
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { Route, Link, BrowserRouter as Router, Switch, useRouteMatch, useParams } from 'react-router-dom';
import './js/i18n/i18n';
import Events from "./js/components/events";
import App from "./js/components/app";
import Kanban from "./js/components/kanban";
import Notfound from './js/components/notfound';
import FoursafeAPI from './js/utils/foursafeApi';
import TrafficGraph from './js/components/traffic_graph';

import * as config from './js/utils/config';
import reducer from './js/reducers';

import './css/style.scss';
/*
function Street() {
    let { streetId } = useParams();
    console.log('street ID: ' + streetId);
    
    return (
        <App />
    );
}
*/
function Streets() {
    let match = useRouteMatch();
  
    return (
        <Router>
            <div>
                <Switch>
                    <Route path={`${match.path}/:streetId`}>
                        <Street />
                    </Route>
                    <Route path={match.path}>
                        <App />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

function Application() {
    let match = useRouteMatch();
    let style = match.path.split("/")[1];
  
    return (
        <Router>
            <div>
                <Switch>
                    <Route path={match.path}>
                        <Suspense fallback="loading">
                            <App style={style}/>
                        </Suspense>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

function Graph() {
    return (
        <Router>
            <div>
                <Switch>
                    <Route path={useRouteMatch().path}>
                        <Suspense fallback="loading">
                            <TrafficGraph exportMode={true}/>
                        </Suspense>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

const routing = (
	<Provider store={createStore(reducer)}>
		<Router>
      <div>
          <Switch>
              <Route exact path="/" component={Application} />
              <Route exact path="/videowall" component={Application} />
              <Route exact path="/noflood" component={Application} />
              <Route exact path="/trafficgraph" component={Graph} />
              <Route path="/id" component={Streets} />
              <Route path="/ocorrencias" component={Kanban} />
              <Route component={Notfound} />
          </Switch>
      </div>
    </Router>
	</Provider>
)

const initialize = async () => {
    window.foursafeApi = new FoursafeAPI(config.FOURSAFE_APP_KEY, config.FOURSAFE_APP_ID);
    await foursafeApi.init();
    ReactDOM.render(routing, document.getElementById('container'));
}

initialize();
import FoursafeAPI from './foursafeApi';
import * as config from './config';

const serviceUrl = config.SERVICE_URL;
const city_code = config.CITY_CODE;

/**
 * Get Flood Map
 * 
 */
export const getFloodMap = () => {
	return new Promise((resolve,reject) => {
		foursafeApi.fetch('post', `${serviceUrl}/api/risk/getFloodMap`)
		.then(data => resolve(data))
		.catch(error => {
			console.log('Fetch error: ', error);
			reject(error);
		});
	});
}

export const getRoadSegmentsMap = () => {
	return new Promise((resolve,reject) => {
		foursafeApi.fetch('post', `${serviceUrl}/api/risk/getRoadSegmentsMap`)
		.then(data => resolve(data))
		.catch(error => {
			console.log('Fetch error: ', error);
			reject(error);
		});
	});
}

export const getBusTrafficMap = () => {
	return new Promise((resolve,reject) => {
		foursafeApi.fetch('post', `${serviceUrl}/api/risk/getBusTrafficMap`)
		.then(data => resolve(data))
		.catch(error => {
			console.log('Fetch error: ', error);
			reject(error);
		});
	});
}

export const getBusTrafficList = () => {
	return new Promise((resolve,reject) => {
		foursafeApi.fetch('post', `${serviceUrl}/api/risk/getBusTrafficList`)
		.then(data => resolve(data))
		.catch(error => {
			console.log('Fetch error: ', error);
			reject(error);
		});
	});
}

export const getBusTrafficDetail = (timestamp, segment_id) => {
	const options = {
		body: JSON.stringify({
			timestamp: timestamp,
			segment_id: segment_id
		})
	}
	return new Promise((resolve,reject) => {
		foursafeApi.fetch('post', `${serviceUrl}/api/risk/getBusTrafficDetail`, options)
		.then(data => resolve(data))
		.catch(error => {
			console.log('Fetch error: ', error);
			reject(error);
		});
	});
}

export const getDetails = (segment_id) => {
	return new Promise((resolve,reject) => {
		foursafeApi.fetch('post', `${serviceUrl}/api/urbii/indicators/details/${segment_id}`)
		.then(data => resolve(data))
		.catch(error => {
			console.log('Fetch error: ', error);
			reject(error);
		});
	});
}

export const getBusGraphData = (timezone) => {
	const options = {
		body: JSON.stringify({
			timezone: timezone
		})
	}
	return new Promise((resolve,reject) => {
		foursafeApi.fetch('post', `${serviceUrl}/api/risk/getBusTrafficTimeSeries`, options)
		.then(data => resolve(data))
		.catch(error => {
			console.log('Fetch error: ', error);
			reject(error);
		});
	});
}

export const getBusTrafficMetrics = () => {
	return new Promise((resolve,reject) => {
		foursafeApi.fetch('post', `${serviceUrl}/api/risk/getBusTrafficMetrics`)
		.then(data => resolve(data))
		.catch(error => {
			console.log('Fetch error: ', error);
			reject(error);
		});
	});
}

export const getTrafficBusLines = () => {
	return new Promise((resolve,reject) => {
		foursafeApi.fetch('post', `${serviceUrl}/api/risk/getTrafficBusLines`)
		.then(data => resolve(data))
		.catch(error => {
			console.log('Fetch error: ', error);
			reject(error);
		});
	});
}

export const getMobilityCityData = () => {
	const options = {
		body: JSON.stringify({
			city_code: city_code
		})
	}
	return new Promise((resolve,reject) => {
		foursafeApi.fetch('post', `${serviceUrl}/api/risk/getMobilityCityData`, options)
		.then(data => resolve(data))
		.catch(error => {
			console.log('Fetch error: ', error);
			reject(error);
		});
	});
}

export const getGifRadar = async url => {
    const response = await fetch(url)
	const data = await response
	return data
}

export const getChart = async url => {
    const response = await fetch(url)
	const data = await response.json()
	return data
}

export const getIndicator = async url => {
    const response = await fetch(url, {method: 'post'})
	const data = await response.json()
	return data
}
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN from '../../../locales/en/translation.json';
import translationPT from '../../../locales/pt/translation.json';

const fallbackLng = ['pt']; 
const availableLanguages = ['pt', 'en'];

// the translations
//TODO: Corrigir importação dos arquivos de i18n
const resources = {
  en: {
    translation: translationEN
  },
  pt: {
    translation: translationPT
  }
};

const options = {
  //order: ['navigator', 'querystring'],
  lookupQuerystring: 'lng'
}

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    //resources,
    detection: options,
    fallbackLng: fallbackLng,
    whitelist: availableLanguages,
    supportedLngs: availableLanguages,
    nonExplicitSupportedLngs: true,
    debug: false,
    backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      wait: true,
      bindI18n: 'i18n loaded',
    },
  });

export default i18n;
import React from 'react';
import Skeleton from 'react-loading-skeleton';

class Events extends React.Component {
    render() {
      const { params } = this.props.match
      return (
        <div>
          <h1>Events</h1>
          <p>{params.id || <Skeleton />}</p>
        </div>
      )
    }
}

export default Events
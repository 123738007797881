import React, { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import TopBar from './topbar';
import { Container, Row, Col, Toast } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { ToastContainer } from 'react-toastify';
import Board from '@asseinfo/react-kanban';
import { getIndicator } from '../utils/apis';
import { RELOAD_TIME } from '../utils/config';

class Kanban extends React.Component {
    constructor(props) {
        super(props);

        const skeleton_data = {
            columns: [
                {
                  id: 1,
                  title: 'Abertas',
                  cards: []
                },
                {
                  id: 2,
                  title: 'Em Andamento',
                  cards: []
                },
                {
                  id: 3,
                  title: 'Resolvidas',
                  cards: []
                }
              ]
        }

        
        console.log('BOARD')
        this.state = {
            is_loading: true,
            board_data: skeleton_data
        }
    }

    async getData(){
        console.log('Request')
        
        const opened_events = await getIndicator('https://dev.foursafe.com/api/urbii/events/opened')
        const events_in_progress = await getIndicator('https://dev.foursafe.com/api/urbii/events/progress')
        const closed_events = await getIndicator('https://dev.foursafe.com/api/urbii/events/closed')

        const events_data = {
            columns: [
                {
                  id: 1,
                  title: `Abertas - ${opened_events['data'].length}`,
                  cards: []
                },
                {
                  id: 2,
                  title: `Em Andamento - ${events_in_progress['data'].length}`,
                  cards: []
                },
                {
                  id: 3,
                  title: `Resolvidas - ${closed_events['data'].length}`,
                  cards: []
                }
              ]
        }

        for(let event of opened_events.data){
            events_data.columns[0].cards.push({
                id: event['_id'],
                title: event['properties']['parent_occurrence_type'],
                description: `${event['properties']['logradouro']}, ${event['properties']['numero']} - ${event['properties']['neighborhood']}`  
            })
        }

        for(let event of events_in_progress.data){
            events_data.columns[1].cards.push({
                id: event['_id'],
                title: event['properties']['parent_occurrence_type'],
                description: `${event['properties']['logradouro']}, ${event['properties']['numero']} - ${event['properties']['neighborhood']}`  
            })
        }

        for(let event of closed_events.data){
            events_data.columns[2].cards.push({
                id: event['_id'],
                title: event['properties']['parent_occurrence_type'],
                description: `${event['properties']['logradouro']}, ${event['properties']['numero']} - ${event['properties']['neighborhood']}`  
            })
        }

        this.setState({
            is_loading: false,
            board_data: events_data
        })

        console.log('events_data')

    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState({ 
                time: Date.now(),
                is_loading: true
             });
             this.getData()
            console.log('Map reloaded at ', new Date().toLocaleString());
        }, RELOAD_TIME);
        this.getData()
    }

    render() {
        const { t } = this.props;
        const board = this.state.board_data;
        // let style = this.props.style;

        console.log('atualizou')

        return (
        
        <Container fluid='true' style={{background: '#1A2323', color: '#ffffff'}} className='px-0'>
            <Suspense fallback={(<Skeleton />)}>
                <TopBar title={'Ocorrências'} />
            </Suspense>
            <Row style={{height:'90vh'}} className='px-5 pb-3'>
                <Col md={12}>
                    <Board children={board} disableColumnDrag disableCardDrag />
                </Col>
            </Row>
            <ToastContainer />
        </Container>
            
        );
    }
        
        

}

export default withTranslation()(Kanban);
import React, { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faVolumeUp, faTint, faEllipsisV, faCircle, faBus, faWater, faChevronLeft, faChevronRight, faTachometerAlt, faStopwatch, faCloudShowersHeavy, faRetweet, faRoad, faExclamationTriangle, faInfo, faArrowsAltH, faVideo, faRulerHorizontal } from '@fortawesome/free-solid-svg-icons';
//import { latLngBounds } from 'leaflet';
import Skeleton from 'react-loading-skeleton';
import SkeletonStreet from './skeletons';
import { risk_class, getFloodRisk, calculateBusIndicatorsRisk, getVariationText } from '../utils/risks';
import { getBusTrafficList, getDetails, getTrafficBusLines, getIndicator } from '../utils/apis';
import { RESET_VIEW_CMD, RELOAD_TIME, TOASTS_CONFIG } from '../utils/config';
import { toast } from 'react-toastify';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import LandslideRiskLogo from '../../img/icons/risco/highRiskMediumImage.svg';
import foursafeLogo from '../../img/foursafe_logo_small.svg';
import watertank from '../../img/watertank.svg';
import { DATA_LIST, DETAILS, DISTRITOS_DATA, DISTRITO_DETAILS } from '../../data/petropolis/mock_city_data';

import styles from '../../css/style.scss';
import { Schemas } from 'aws-sdk';
import { toHtml } from '@fortawesome/fontawesome-svg-core';

const showRiskCondition = (traffic_class, risk_class) => {
    const flood_conditions = ['Sem evento', null, undefined, 'null'];
    const traffic_conditions = [undefined, null, 'null'];

    return (!traffic_conditions.includes(traffic_class) || !flood_conditions.includes(risk_class))
}

class DetailComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            properties: null,
            is_left_tab_active: true,
            selectedTab: 0,
            isLoading: true,
            segment_id: this.props.segment_id,
            name: this.props.name,
            timestamp: this.props.timestamp,
            showMenu: false
        }
    }

    componentDidMount() {
        getDetails(this.props.segment_id).then(detailedData => {
            this.setState({
                properties2: detailedData,
                properties: DETAILS,
                isLoading: false
            });
            this.sendData(true, detailedData);
        });
        document.addEventListener("click", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside);
    }

    sendData = (close_command, cameras_layer) => {
        this.props.parentCallback(close_command, cameras_layer);
    }

    setActiveColor(tab, status_color) {
        let selectedTab = this.state.selectedTab;
        let is_active = (tab == selectedTab);
        let active_color = '#293131';
        if (status_color != null && status_color != undefined && is_active){
            active_color = status_color;
        }
        return active_color
    }

    renderListItem(obj, datetime=null) {
        const { t } = this.props;
        let time='', date='';
        // if (datetime){
        //     date = datetime.toLocaleDateString();
        //     time = datetime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
        // }

        let id = obj.id
        let text = obj.name

        if (obj.length == 0){
            return (
                <Row key={'none'} className='w-100'>
                    <Col md={12}>
                        <span className="" style={{fontSize: '1.9vh'}}> - Não há informação disponível </span>
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row key={id} className='w-100'>
                    <Col md={12}>
                        <span className="" style={{fontSize: '1.9vh'}}>{text}</span>
                    </Col>
                </Row>
            )
        }
    }

    renderItem(id, text, datetime=null) {
        const { t } = this.props;
        let time='', date='';
        // if (datetime){
        //     date = datetime.toLocaleDateString();
        //     time = datetime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
        // }

        return (
            <Row key={id} className='w-100'>
                <Col md={12}>
                    <span className="" style={{fontSize: '1.9vh'}}>{text}</span>
                    {
                        datetime ?
                        <span className="" style={{fontSize: '1.5vh'}}> - {t('dashboard.details.events.start_datetime', {time: time, date:date})}</span>
                        :
                        ''
                    }
                </Col>
            </Row>
        )
    }

    renderItens(array){
        if (array.length == 0){
            return (
                <Row key={'none'} className='w-100'>
                    <Col md={12}>
                        <span className="" style={{fontSize: '1.9vh'}}> - Não há informação disponível </span>
                    </Col>
                </Row>
            )
        } else {
            for(let id in array.length){
                renderItem(id, array[id])
            }
            
        }
    }

    renderCameras(data) {
        let camera_code = data.properties.code;
        let description = data.properties.description;
        
        return this.renderItem(camera_code, camera_code + ' - ' + description)
    }

    renderEvents(data) {
        let title = data.properties.title;
        let description = data.properties.description;
        let start_datetime = new Date(data.properties.start_datetime);

        return this.renderItem(title, title + ' - ' + description, start_datetime)
    }

    renderBusLines(data) {
        let line_number = data.line.toString();
        let isActiveVariantColor = data.isActiveNow ? 'light' : 'secondary';
        let line_count = data.count;
        let showLineCount = line_count ? true : false;
        // let line_speed = data.speed;
        // let line_time = data.time;

        return(
            // <Row key={line_number} className="text-center dashboard-street-bus-lines__wrap">
            //     <Col md={3}>
            //         {/* <Button href="#" variant="primary" disabled style={{backgroundColor: 'blue', borderRadius: '20px'}}>
            //             <span style={{fontSize: "1.3rem"}}>010 </span>
            //         </Button> */}
            //         <h6>
            //             <Badge variant="secondary" className="dashboard-street-bus-line">{line_number}</Badge>
            //         </h6>
            //     </Col>
            //     <Col md={2}>
            //         <h6>
            //             <Badge variant="secondary" className="dashboard-street-bus-line">{t('dashboard.risks.bus_traffic.bus_lines.qnty.vehicle', {count: line_count})}</Badge>
            //         </h6>
            //     </Col>
            //     <Col md={5}>
            //         <span>{t('dashboard.risks.bus_traffic.bus_lines.values', {value_speed: line_speed, value_time: line_time})}</span>
            //     </Col>
            //     <Col md={2}>
            //         <FontAwesomeIcon icon={faWhatsapp} size="1x" transform="down-4" color="darkgray"/>
            //     </Col>
            // </Row>
            <Col key={line_number} lg={3} xl={2} className="text-center dashboard-street-bus-lines__wrap">
                <h6>
                    <Badge variant={isActiveVariantColor} className="dashboard-street-bus-line">
                        { showLineCount &&
                            <span className="fa-layers fa-fw" style={{display: 'contents'}}>
                                <span className="fa-layers-counter" style={{background: styles.camera_icon_color, fontSize: '5em', opacity: 0.9}}>{line_count}</span>
                            </span>
                        }
                        {line_number}
                    </Badge>
                </h6>
            </Col>
        )
    }

    toggleMenu = event => {
        event.preventDefault();
        this.setState({
            showMenu: !this.state.showMenu,
        });
    }

    handleClickOutside = event => {
        event.preventDefault();
        if (this.dropdownDetailMenu && !this.dropdownDetailMenu.contains(event.target)){
            this.setState({
                showMenu: false
            });
        }
    };

    renderDropDown() {
        const { t } = this.props;
        return (
            <Row className="" style={{position: 'absolute', top: '9%', right: '5%', backgroundColor: '#1f2727', borderRadius: '5px', minWidth: '100px', boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)', zIndex: 1}}>
                <Col>
                    {/*<Row>
                        <Col md={12} className="dropdown-option p-4">{t('dashboard.dropdown.export_pdf')}</Col>
                    </Row>
                    <Row>
                        <Col md={12} className='p-0'><hr className='my-0' /></Col>
                    </Row>
                    <Row>
                        <Col md={12} className="dropdown-option p-4">{t('dashboard.dropdown.export_image')}</Col>
                    </Row> */}
                    <Row>
                        <Col md={12} className='p-0'><hr className='my-0' /></Col>
                    </Row>
                    <Row>
                        <Col md={12} className="dropdown-option p-4 cursor-pointer" onClick={e => this.copyText(e)}>{t('dashboard.dropdown.copy_text')}</Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    copyText = (event) => {
        this.toggleMenu(event);
        toast.info('👨🏾‍💻 Ainda estamos em desenvolvimento! Tente novamente em breve!', TOASTS_CONFIG);
    }

    render() {
        const { t } = this.props;
        let isLoading = this.state.isLoading;
        let selectedTab = this.state.selectedTab;
        let address, basin, bus_status, bus_status_color, bus_lines, bus_lines_now, risk_class, flood_status, rain_status, segment_size, last_3week_avg, risk_1h, last_3week_flow, spacing , spacing_variation, last_3week_spacing 
        let acc_1h, acc_24h, acc_72h,average_bus_speed, avg_variation, trend_1h, trend_24h, risk_24h, risk_72h, trend_72h, average_time_travel, trip_time_variation, last_3week_trip_time, density, density_variation, last_3week_density, flow, flow_variation
        let style_risk_1h, style_risk_24h, style_risk_72h, style_risk_landslide, color_risk_1h, color_risk_24h, color_risk_72h, color_risk_landslide, landslide
        let events=[], cameras=[], data_bus_lines=[], support_points = [], new_events = [], past_events = [], alarm_siren = [];
        if (isLoading == false) {

            // TODO: Mudar quando fizer as mudanças
            basin = this.state.properties2.name;
            address = this.state.properties.segment.name;
            let separator = ', ';
            let lane = this.state.properties.segment.lane;
            if (lane) {
                address += ''
            }
            let destination = this.state.properties.segment.destination;
            if (destination) {
                address += ''
            }
            let reference = this.state.properties.segment.ref;
            if (reference) {
                address += ''
            }
            // Mobility (Bus) Data
            // Indicador - Velocidade Média
            acc_1h = this.state.properties2.indicators['1h'].acc.toFixed(2)
            risk_1h = this.state.properties2.indicators['1h'].trend;
            // Tendência de 1h

            if (risk_1h < 0) {
                trend_1h = "Tendência: ▼"
            } else if (risk_1h > 0){
                trend_1h = "Tendência: ▲"
            } else {
                trend_1h = "Tendência: -"
            }

            style_risk_1h = this.state.properties2.indicators['1h'].risk
            if(style_risk_1h == null){
                color_risk_1h = styles['risk_none']
            } else {
                color_risk_1h = styles[`risk_${style_risk_1h}`]
            }


            avg_variation = this.state.properties.metrics.avg_variation;

            // TODO: Mudar para risco geral
            bus_status = this.state.properties2.indicators.landslide['risk_class'];
            if (bus_status == null) {
                bus_status = 'none';
            }
            bus_status_color = styles[`risk_${bus_status}`];


            // Indicador - Tempo Médio (de Travessia)
            acc_24h = this.state.properties2.indicators['24h'].acc.toFixed(2);

            trip_time_variation = this.state.properties.metrics.trip_time_variation;

            risk_24h = this.state.properties2.indicators['24h'].trend;
            if (risk_24h < 0) {
                trend_24h = "Tendência: ▼"
            } else if (risk_24h > 0){
                trend_24h = "Tendência: ▲"
            } else {
                trend_24h = "Tendência: -"
            }

            style_risk_24h = this.state.properties2.indicators['24h'].risk
            if(style_risk_24h == null){
                color_risk_24h = styles['risk_none']
            } else {
                color_risk_24h = styles[`risk_${style_risk_24h}`]
            }

            bus_lines = this.state.properties.metrics.daily_bus_lines.map(item => {
                item = item.replace(/[^0-9\.]+/g,"");
                return Number(item)
            });
            
            last_3week_trip_time = Math.ceil(this.state.properties.metrics.last_3week_trip_time);
            // Indicador - Densidade
            landslide = (this.state.properties2.indicators.landslide['risck_scale']*100).toFixed(0);

            style_risk_landslide = this.state.properties2.indicators.landslide['risk_class']

            if(style_risk_landslide == null){
                color_risk_landslide = styles['risk_none']
            } else {
                color_risk_landslide = styles[`risk_${style_risk_landslide}`]
            }
            

            density_variation = this.state.properties.metrics.density_variation;
            last_3week_density = Math.ceil(this.state.properties.metrics.last_3week_density);
            // Indicador - Comprimento
            segment_size = parseFloat(this.state.properties.metrics.segment_size.toFixed(1)).toLocaleString();
            // Indicador - Fluxo
            acc_72h = this.state.properties2.indicators['72h'].acc.toFixed(2);
            risk_72h = this.state.properties2.indicators['24h'].trend;
            if (risk_72h < 0) {
                trend_72h = "Tendência: ▼"
            } else if (risk_72h > 0){
                trend_72h = "Tendência: ▲"
            } else {
                trend_72h = "Tendência: -"
            }

            style_risk_72h = this.state.properties2.indicators['72h'].risk
            if(style_risk_72h == null){
                color_risk_72h = styles['risk_none']
            } else {
                color_risk_72h = styles[`risk_${style_risk_72h}`]
            }


            flow_variation = this.state.properties.metrics.flow_variation;
            last_3week_flow = Math.ceil(this.state.properties.metrics.last_3week_flow);
            // Indicador - Espaçamento
            spacing = Math.ceil(this.state.properties.metrics.spacing*1000); // Conversão de quilômetros para metros (x1000)
            spacing_variation = this.state.properties.metrics.spacing_variation;
            last_3week_spacing = Math.ceil(this.state.properties.metrics.last_3week_spacing*1000); // Conversão de quilômetros para metros
            // Linhas de Ônibus no Trecho

            support_points = this.state.properties2.details['support_points']
            new_events = this.state.properties2.events['new_events']
            past_events = this.state.properties2.events['past_events']
            alarm_siren = this.state.properties2.details['alarm_siren']

            bus_lines_now = this.state.properties.metrics.bus_lines;
            let bus_lines_now_keys_array = Object.keys(bus_lines_now).map(item => {
                item = item.replace(/[^0-9\.]+/g,"");
                return Number(item)
            });
            let complete_bus_lines = bus_lines_now_keys_array.concat(bus_lines);
            complete_bus_lines = [...new Set([...bus_lines_now_keys_array,...bus_lines])].sort((a, b) => a - b)
            for (let line of complete_bus_lines) {
                data_bus_lines.push(this.renderBusLines({
                    line: line,
                    isActiveNow: bus_lines_now_keys_array.includes(line),
                    count: bus_lines_now[line],
                    //speed: 30,
                    //time: 5
                }))
            }
            // Câmeras Próximas
            this.state.properties.segment.cameras
            .sort((a, b) => (Number(a.properties.code) > Number(b.properties.code)) ? 1 : ((Number(b.properties.code) > Number(a.properties.code)) ? -1 : 0)) // Sort ordena pelo número das câmeras
            .forEach(element => { 
                cameras.push(this.renderCameras(element));
            });
            if (cameras.length == 0){
                cameras.push(this.renderItem('no_cameras', t('dashboard.details.info.cameras.no_cameras')));
            }
            // Ocorrências Próximas
            let events_array = this.state.properties.metrics.events;
            //events_array = [ {'properties': {'title': 'Obra na Via (RIO ÁGUAS)', 'description': 'Rua Batista da Costa - Alt. R. Jardim Botânico - Jardim Botânico - Sent. Av. Lineu de Paula Machado', 'start_datetime': '2020-11-12 08:05:29.000Z'}}] // Para teste apenas
            events_array.forEach(element => {
                events.push(this.renderEvents(element));
            });
            if (events.length == 0){
                events.push(this.renderItem('no_cameras', t('dashboard.details.events.no_events')));
            }
            // // Alagamento
            // risk_class = risk_class[this.state.properties.metrics.risk_class];
            // flood_status = getFloodRisk(this.state.properties.metrics.risk_proba);
            // flood_status_color = (risk_class == 'no_event') ? styles[`risk_low`] : styles[`risk_${flood_status}`];
            // flood_duration = Math.ceil(this.state.properties.metrics.flood_duration);
            // Chuva
            // rain_status = this.state.properties.metrics.rainfall_class;
            // rain_qnty = typeof(this.state.properties.metrics.precipitation_acc1h) !== 'undefined' ? this.state.properties.metrics.precipitation_acc1h : '-';
        }

        return(
            <div className="dashboard-street__wrap">
                <Row className='justify-content-between'>
                    <Col md={1} onClick={() => this.sendData(false, [])} className='cursor-pointer'>
                        <span className="fa-layers fa-fw dashboard-regular-text">
                            <FontAwesomeIcon icon={faCircle} size='2x' transform="left-3" color={'#293535'} />
                            <FontAwesomeIcon icon={faChevronLeft} size='lg' transform="shrink-8" inverse />
                        </span>
                    </Col>
                    <Col md={10} className="dashboard-title p-4 ml-n5" style={{display: 'flex', alignItems: 'left'}}>
                        
                        {!isLoading ? basin : <Skeleton />}

                    </Col>
                    <Col md={1}/>
                    {/* <Col md={1} className='cursor-pointer' style={{textAlign: 'center'}} ref={(element) => {this.dropdownDetailMenu = element}} onClick={this.toggleMenu}>
                        <FontAwesomeIcon icon={faEllipsisV} size="xs" />
                    </Col> */}
                </Row>
                {this.state.showMenu ? this.renderDropDown() : null}
                <Row className='align-items-center'>
                    <Col md className='p-0'>
                        <hr className='my-0' />
                    </Col>
                </Row>
                
                <Row >
                    <Col md={12}>
                        <Row >
                            <Col md={4} className="cursor-pointer py-3" onClick={() => this.setState({selectedTab: 0})} style={{background: this.setActiveColor(0, bus_status_color)}}>
                                <Row className="dashboard-street-icon__wrap align-items-center">
                                    <FontAwesomeIcon icon={faTachometerAlt} size="sm" /> <span className="dashboard-street-icon ml-3" style={{fontSize: '2.2rem'}}>{t('dashboard.details.indicators')}</span>
                                </Row>
                            </Col>
                            <Col md={4} className="cursor-pointer py-3" onClick={() => this.setState({selectedTab: 1})} style={{background: this.setActiveColor(1, bus_status_color)}}>
                                <Row className="dashboard-street-icon__wrap align-items-center">
                                    <FontAwesomeIcon icon={faInfo} size="sm" /><span className="dashboard-street-icon ml-3" style={{fontSize: '2.2rem'}}>{t('dashboard.details.info.title')}</span>
                                </Row>
                            </Col>
                            <Col md={4} className="cursor-pointer py-3" onClick={() => this.setState({selectedTab: 2})} style={{background: this.setActiveColor(2, bus_status_color)}}>
                                <Row className="dashboard-street-icon__wrap align-items-center">
                                    <FontAwesomeIcon icon={faExclamationTriangle} size="sm" /> <span className="dashboard-street-icon ml-3" style={{fontSize: '2.2rem'}}>{t('dashboard.details.events.title')}</span>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mt-2 dashboard-street-details">
                            { (selectedTab == 1) ? 
                                (
                                    <>
                                        <Col md={6} className='pr-2'>
                                            <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-3" color={'#262d2d'} />
                                                        <FontAwesomeIcon icon={faHome} size='lg' transform="shrink-6 left-1" />
                                                    </span>
                                                </Col>
                                                <Col md={11} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t('dashboard.risks.bus_traffic.bus_lines.title') && 'Pontos de Apoio'}</span>
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-between px-4 dashboard-details-tab-item'>
                                                <Col md={12}>
                                                    <Row>
                                                        {/* {!isLoading ? data_bus_lines : <Skeleton width={500} height={'23vh'} />} */}
                                                        {(support_points.length != 0 && support_points != undefined) ? support_points.map(item => this.renderListItem(item)) : this.renderItens([])}
                                                        {/* {this.renderItem(1, 'Escola Municipal Ana Mohammad')}
                                                        {this.renderItem(2, 'Escola Estadual Augusto Meshick')}
                                                        {this.renderItem(3, 'Escola Municipal Governador Marcello Alencar')}
                                                        {this.renderItem(4, 'E.M. Rosalina Nicolay')} */}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6} className='pr-5'>
                                            <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-2" color={'#262d2d'} />
                                                        <FontAwesomeIcon icon={faVolumeUp} size='lg' transform="shrink-6" />
                                                    </span>
                                                </Col>
                                                <Col md={10} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t('dashboard.details.info.cameras.title') && 'Sirenes'}</span>
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-between px-4 dashboard-details-tab-item'>
                                                <Col md={{ span: 10, offset: 1 }}>
                                                    <Row>
                                                        {/* {!isLoading ? cameras : <Skeleton width={500} height={'23vh'} />} */}
                                                        {(alarm_siren.length != 0 && alarm_siren != undefined) ? alarm_siren.map(item => this.renderListItem(item)) : this.renderItens([])}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </>
                                )
                            : (selectedTab == 0) ?
                                (
                                    <>
                                        <Col md={6} className="dashboard-details-tab">
                                            <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-3" color={color_risk_1h} />
                                                        <FontAwesomeIcon icon={faTint} size='lg' transform="shrink-6" />
                                                        <span className="fa-layers-counter" style={{background: styles.camera_icon_color, fontSize: '2em', opacity: 0.9, right: '-15px'}}>1h</span>
                                                    </span>
                                                </Col>
                                                <Col md={7} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{ t('dashboard.risks.bus_traffic.average_bus_speed.title')}</span>
                                                </Col>
                                                <Col md={4} style={{borderRadius: '50px', background: '#c4c4c4', color: '#353535', fontSize: '0.6em', textAlign: 'center'}} className='py-2'>
                                                    {t('dashboard.risks.bus_traffic.average_bus_speed.value', {value: acc_1h})}
                                                </Col>
                                                <Col md={12}>
                                                    <span style={{fontSize: '1.2rem'}}>{trend_1h}</span>
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-3" color={color_risk_24h} />
                                                        <FontAwesomeIcon icon={faTint} size='lg' transform="shrink-6" />
                                                        <span className="fa-layers-counter" style={{background: styles.camera_icon_color, fontSize: '2em', opacity: 0.9, right: '-20px'}}>24h</span>
                                                    </span>
                                                </Col>
                                                <Col md={7} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t('dashboard.risks.bus_traffic.average_time_travel.title')}</span>
                                                </Col>
                                                <Col md={4} style={{borderRadius: '50px', background: '#c4c4c4', color: '#333d3d', fontSize: '0.6em', textAlign: 'center'}} className='py-2'>
                                                    {t('dashboard.risks.bus_traffic.average_time_travel.value', {time: acc_24h})}
                                                </Col>
                                                <Col md={12}>
                                                    {/* <span style={{fontSize: '1.2rem'}}>{t('dashboard.risks.bus_traffic.average_time_travel.reference_value', {value: last_3week_trip_time})} ({trip_time_variation}%)</span> */}
                                                    <span style={{fontSize: '1.2rem'}}>{trend_24h}</span>
                                                </Col>
                                            </Row>
                                            {/* <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-3" color={styles['risk_' + calculateBusIndicatorsRisk(null)]} />
                                                        <FontAwesomeIcon icon={faRulerHorizontal} size='lg' transform="shrink-6 left-1" />
                                                    </span>
                                                </Col>
                                                <Col md={7} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t('dashboard.risks.bus_traffic.segment_size.title')}</span>
                                                </Col>
                                                <Col md={4} style={{borderRadius: '50px', background: '#c4c4c4', color: '#333d3d', fontSize: '0.6em', textAlign: 'center'}} className='py-2'>
                                                    {t('dashboard.risks.bus_traffic.segment_size.unit', {value: segment_size})}
                                                </Col>
                                            </Row> */}
                                        </Col>
                                        <Col md={6} className='pr-5 dashboard-details-tab'>
                                            <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-3" color={color_risk_72h} />
                                                        <FontAwesomeIcon icon={faTint} size='lg' transform="shrink-6" />
                                                        <span className="fa-layers-counter" style={{background: styles.camera_icon_color, fontSize: '2em', opacity: 0.9, right: '-20px'}}>72h</span>
                                                    </span>
                                                </Col>
                                                <Col md={6} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t('dashboard.risks.bus_traffic.flow.title')}</span>
                                                </Col>
                                                <Col md={5} style={{borderRadius: '50px', background: '#c4c4c4', color: '#333d3d', fontSize: '0.6em', textAlign: 'center'}} className='py-2'>
                                                    {t('dashboard.risks.bus_traffic.flow.value', {count: acc_72h})}
                                                </Col>
                                                <Col md={12}>
                                                    {/* <span style={{fontSize: '1.2rem'}}>{t('dashboard.risks.bus_traffic.flow.reference_value', {value: last_3week_flow})} ({flow_variation}%)</span> */}
                                                    <span style={{fontSize: '1.2rem'}}>{trend_72h}</span>
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-3" color={color_risk_landslide} />
                                                        <img src={LandslideRiskLogo} className='fa-layers-text' style={{width: '2.5rem'}}/>
                                                        {/* <FontAwesomeIcon icon={faRoad} size='lg' transform="shrink-6 left-1" /> */}
                                                        {/* <FontAwesomeIcon icon={faLandslide} size='lg' transform="shrink-6 left-1" /> */}
                                                    </span>
                                                </Col>
                                                <Col md={6} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t('dashboard.risks.bus_traffic.density.title')}</span>
                                                </Col>
                                                <Col md={5} style={{borderRadius: '50px', background: '#c4c4c4', color: '#333d3d', fontSize: '0.6em', textAlign: 'center'}} className='py-2'>
                                                    {t('dashboard.risks.bus_traffic.density.value', {count: landslide})}
                                                </Col>
                                                <Col md={12}>
                                                    {/* <span style={{fontSize: '1.2rem'}}>{t('dashboard.risks.bus_traffic.density.reference_value', {value: last_3week_density})} ({density_variation}%)</span> */}
                                                    <span style={{fontSize: '1.2rem'}}>O risco é a chance de ocorrer um evento.</span>
                                                </Col>
                                            </Row>
                                            {/* <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-3" color={styles['risk_' + calculateBusIndicatorsRisk(spacing_variation)]} />
                                                        <FontAwesomeIcon icon={faArrowsAltH} size='lg' transform="shrink-6 left-0.5" />
                                                    </span>
                                                </Col>
                                                <Col md={6} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t('dashboard.risks.bus_traffic.spacing.title')}</span>
                                                </Col>
                                                <Col md={5} style={{borderRadius: '50px', background: '#c4c4c4', color: '#333d3d', fontSize: '0.6em', textAlign: 'center'}} className='py-2'>
                                                    {t('dashboard.risks.bus_traffic.spacing.value', {count: spacing})}
                                                </Col>
                                                <Col md={12}>
                                                    <span style={{fontSize: '1.2rem'}}>{t('dashboard.risks.bus_traffic.spacing.reference_value', {value: last_3week_spacing})} ({spacing_variation}%)</span>
                                                </Col>
                                            </Row> */}
                                        </Col>
                                    </>
                                )
                            : 
                                (
                                    <>
                                        <Col md={6} className="dashboard-details-tab" style={{overflowY: 'hidden'}}>
                                            {/* <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-3" color={'#262d2d'} />
                                                        <FontAwesomeIcon icon={faCloudShowersHeavy} size='lg' transform="shrink-6" />
                                                    </span>
                                                </Col>
                                                <Col md={7} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t(`dashboard.risks.rain.status.${rain_status}`)}</span>
                                                </Col>
                                                <Col md={4} style={{borderRadius: '50px', background: '#c4c4c4', color: '#333d3d', fontSize: '0.6em', textAlign: 'center'}} className='py-2'>
                                                    {t(`dashboard.risks.rain.description`, {count: rain_qnty})}
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-3" color={'#262d2d'} />
                                                        <FontAwesomeIcon icon={faWater} size='lg' transform="shrink-6 left-2" />
                                                    </span>
                                                </Col>
                                                <Col md={7} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t(`dashboard.risks.flood.class.${risk_class}`)}</span>
                                                </Col>
                                                <Col md={4} style={{borderRadius: '50px', background: '#c4c4c4', color: '#333d3d', fontSize: '0.6em', textAlign: 'center'}} className='py-2'>
                                                    {(risk_class == 'no_event') ? '-' : t(`dashboard.risks.flood.status.${flood_status}`, {risk_class: `$t(dashboard.risks.flood.class.${risk_class})`})}
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-3" color={'#262d2d'} />
                                                        <FontAwesomeIcon icon={faWater} size='lg' transform="shrink-6 left-1" />
                                                    </span>
                                                </Col>
                                                <Col md={7} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t('dashboard.risks.flood.duration_forecast.title')}</span>
                                                </Col>
                                                <Col md={4} style={{borderRadius: '50px', background: '#c4c4c4', color: '#333d3d', fontSize: '0.6em', textAlign: 'center'}} className='py-2'>
                                                    {t('dashboard.risks.flood.duration_forecast.description', {context: risk_class, count: flood_duration})}
                                                </Col>
                                            </Row> */}
                                            <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-2" color={'#262d2d'} />
                                                        <FontAwesomeIcon icon={faExclamationTriangle} size='lg' transform="shrink-6" />
                                                    </span>
                                                </Col>
                                                <Col md={11} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t('dashboard.details.events.title') && 'Ocorrências Abertas'}</span>
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-between px-4 dashboard-details-tab'>
                                                <Col md={{ span: 10, offset: 1 }}>
                                                    <Row>
                                                        {/* {!isLoading ? events : <Skeleton width={500} height={'23vh'}/> }  */}
                                                        {this.renderItens(new_events)}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6} className='pr-5'>
                                            <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-2" color={'#262d2d'} />
                                                        <FontAwesomeIcon icon={faExclamationTriangle} size='lg' transform="shrink-6" />
                                                    </span>
                                                </Col>
                                                <Col md={11} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t('dashboard.details.events.title') && 'Ocorrências Em Andamento'}</span>
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-between px-4 dashboard-details-tab'>
                                                <Col md={{ span: 10, offset: 1 }}>
                                                    <Row>
                                                        {this.renderItens(past_events)} 
                                                        {/* {this.renderItem('o1', 'Exemplo da descrição da Ocorrência #1.')}
                                                        {this.renderItem('o2', 'Exemplo da descrição da Ocorrência #2.')}
                                                        {this.renderItem('o3', 'Exemplo da descrição da Ocorrência #3.')}
                                                        {this.renderItem('o4', 'Exemplo da descrição da Ocorrência #4.')} */}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </>
                                )
                            }
                        </Row>

                    </Col>
                </Row>
            </div>
        );
    }
}

class DistritoComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            properties: null,
            is_left_tab_active: true,
            selectedTab: 0,
            isLoading: true,
            segment_id: this.props.segment_id,
            timestamp: this.props.timestamp,
            showMenu: false
        }
    }

    componentDidMount() {
        let detailedData2 = DISTRITO_DETAILS;
        getDetails(this.props.segment_id).then(detailedData => {
            
            this.setState({
                properties2: detailedData,
                properties: detailedData2,
                isLoading: false
            });

            this.sendData(true, detailedData.segment.cameras);
        });
        document.addEventListener("click", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside);
    }

    sendData = (close_command, cameras_layer) => {
        this.props.parentCallback(close_command, cameras_layer);
    }

    setActiveColor(tab, status_color) {
        let selectedTab = this.state.selectedTab;
        let is_active = (tab == selectedTab);
        let active_color = '#293131';
        if (status_color != null && status_color != undefined && is_active){
            active_color = status_color;
        }
        return active_color
    }

    renderListItem(obj, datetime=null) {
        const { t } = this.props;
        let time='', date='';
        // if (datetime){
        //     date = datetime.toLocaleDateString();
        //     time = datetime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
        // }

        let id = obj.id
        let text = obj.name

        if (obj.length == 0){
            return (
                <Row key={'none'} className='w-100'>
                    <Col md={12}>
                        <span className="" style={{fontSize: '1.9vh'}}> - Não há informação disponível </span>
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row key={id} className='w-100'>
                    <Col md={12}>
                        <span className="" style={{fontSize: '1.9vh'}}>{text}</span>
                    </Col>
                </Row>
            )
        }

        
    }

    renderItem(id, text, datetime=null) {
        const { t } = this.props;
        let time='', date='';
        // if (datetime){
        //     date = datetime.toLocaleDateString();
        //     time = datetime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
        // }

        return (
            <Row key={id} className='w-100'>
                <Col md={12}>
                    <span className="" style={{fontSize: '1.9vh'}}>{text}</span>
                    {
                        datetime ?
                        <span className="" style={{fontSize: '1.5vh'}}> - {t('dashboard.details.events.start_datetime', {time: time, date:date})}</span>
                        :
                        ''
                    }
                </Col>
            </Row>
        )
    }

    renderItens(array){
        if (array.length == 0){
            return (
                <Row key={'none'} className='w-100'>
                    <Col md={12}>
                        <span className="" style={{fontSize: '1.9vh'}}> - Não há risco em nenhum bairro </span>
                    </Col>
                </Row>
            )
        } else {
            for(let element in array){
                
                let id = array[element].id
                let item = array[element].name
                return this.renderItem(id, item)
            }
            
        }
    }

    renderCameras(data) {
        let camera_code = data.properties.code;
        let description = data.properties.description;
        
        return this.renderItem(camera_code, camera_code + ' - ' + description)
    }

    renderEvents(data) {
        let title = data.properties.title;
        let description = data.properties.description;
        let start_datetime = new Date(data.properties.start_datetime);

        return this.renderItem(title, title + ' - ' + description, start_datetime)
    }

    renderBusLines(data) {
        let line_number = data.line.toString();
        let isActiveVariantColor = data.isActiveNow ? 'light' : 'secondary';
        let line_count = data.count;
        let showLineCount = line_count ? true : false;
        // let line_speed = data.speed;
        // let line_time = data.time;

        return(
            // <Row key={line_number} className="text-center dashboard-street-bus-lines__wrap">
            //     <Col md={3}>
            //         {/* <Button href="#" variant="primary" disabled style={{backgroundColor: 'blue', borderRadius: '20px'}}>
            //             <span style={{fontSize: "1.3rem"}}>010 </span>
            //         </Button> */}
            //         <h6>
            //             <Badge variant="secondary" className="dashboard-street-bus-line">{line_number}</Badge>
            //         </h6>
            //     </Col>
            //     <Col md={2}>
            //         <h6>
            //             <Badge variant="secondary" className="dashboard-street-bus-line">{t('dashboard.risks.bus_traffic.bus_lines.qnty.vehicle', {count: line_count})}</Badge>
            //         </h6>
            //     </Col>
            //     <Col md={5}>
            //         <span>{t('dashboard.risks.bus_traffic.bus_lines.values', {value_speed: line_speed, value_time: line_time})}</span>
            //     </Col>
            //     <Col md={2}>
            //         <FontAwesomeIcon icon={faWhatsapp} size="1x" transform="down-4" color="darkgray"/>
            //     </Col>
            // </Row>
            <Col key={line_number} lg={3} xl={2} className="text-center dashboard-street-bus-lines__wrap">
                <h6>
                    <Badge variant={isActiveVariantColor} className="dashboard-street-bus-line">
                        { showLineCount &&
                            <span className="fa-layers fa-fw" style={{display: 'contents'}}>
                                <span className="fa-layers-counter" style={{background: styles.camera_icon_color, fontSize: '5em', opacity: 0.9}}>{line_count}</span>
                            </span>
                        }
                        {line_number}
                    </Badge>
                </h6>
            </Col>
        )
    }

    toggleMenu = event => {
        event.preventDefault();
        this.setState({
            showMenu: !this.state.showMenu,
        });
    }

    handleClickOutside = event => {
        event.preventDefault();
        if (this.dropdownDetailMenu && !this.dropdownDetailMenu.contains(event.target)){
            this.setState({
                showMenu: false
            });
        }
    };

    

    renderNeighborhoodsList = (name, risk_foursafe, risk_tank, value_foursafe) => {

        let name_local = name
        let number_risk_foursafe = value_foursafe == null ? '-' : `${(value_foursafe*100).toFixed(0)}%`
        let number_risk_tank = risk_tank == null ? '-' : `${(risk_tank*100).toFixed(0)}%`
        let color_value_foursafe =  risk_foursafe == null ? 'none': risk_foursafe

        if((color_value_foursafe != ('none')) && (color_value_foursafe != ('low'))) {
            // Comentar antes de subir
            // window.alert(`${name_local} apresentou risco ${risk_foursafe}`) 
            return (
                
                <Row className='justify-content-between py-3'>
                    <Col md={{ span: 1, offset: 1 }} className='px-3'>
                        <span className="fa-layers fa-fw dashboard-regular-text">
                            <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-3" color={styles['risk_'+ color_value_foursafe]} />
                            <img src={LandslideRiskLogo} className='fa-layers-text' style={{width: '2.5rem'}}/>
                        </span>
                    </Col>
                    <Col md={5} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                        <span style={{fontSize: '1.8rem'}}>{name_local}</span>
                    </Col>
                    <Col md={5} style={{borderRadius: '50px', background: '#c4c4c4', color: '#333d3d', fontSize: '0.6em', textAlign: 'center'}} className='py-2'>
                        <Row className="justify-content-md-center">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-top"><span className='tooltip-risk'>Modelo Foursafe®</span></Tooltip>}><Col md={3} className="text-center cursor-pointer" style={{fontSize: '0.6em', width: "100%"}}>{number_risk_foursafe}</Col></OverlayTrigger>
                            <Col md={1} className="text-center" style={{fontSize: '0.6em', width: "100%"}}>|</Col>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-top"><span className='tooltip-risk'>Modelo Tanque</span></Tooltip>}><Col md={3} className="text-center cursor-pointer" style={{fontSize: '0.6em', width: "100%"}}>{number_risk_tank}</Col></OverlayTrigger>
                        </Row>
                    </Col>
                </Row>
            )
        }

        

    }

    setNeighborhoodsList = data => {

        const name = data.name
        const risk_foursafe = data.risk_foursafe
        const risk_tank = data.risk_tank
        const value_foursafe = data.value_foursafe
        return this.renderNeighborhoodsList(name, risk_foursafe, risk_tank, value_foursafe)
    }

    renderDropDown() {
        const { t } = this.props;
        return (
            <Row className="" style={{position: 'absolute', top: '9%', right: '5%', backgroundColor: '#1f2727', borderRadius: '5px', minWidth: '100px', boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)', zIndex: 1}}>
                <Col>
                    {/*<Row>
                        <Col md={12} className="dropdown-option p-4">{t('dashboard.dropdown.export_pdf')}</Col>
                    </Row>
                    <Row>
                        <Col md={12} className='p-0'><hr className='my-0' /></Col>
                    </Row>
                    <Row>
                        <Col md={12} className="dropdown-option p-4">{t('dashboard.dropdown.export_image')}</Col>
                    </Row> */}
                    <Row>
                        <Col md={12} className='p-0'><hr className='my-0' /></Col>
                    </Row>
                    <Row>
                        <Col md={12} className="dropdown-option p-4 cursor-pointer" onClick={e => this.copyText(e)}>{t('dashboard.dropdown.copy_text')}</Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    copyText = (event) => {
        this.toggleMenu(event);
        toast.info('👨🏾‍💻 Ainda estamos em desenvolvimento! Tente novamente em breve!', TOASTS_CONFIG);
    }

    render() {
        const { t } = this.props;
        let isLoading = this.state.isLoading;
        let selectedTab = this.state.selectedTab;
        let address, time, bus_status, bus_status_color, bus_lines, bus_lines_now, risk_class, flood_status, flood_status_color, risk_1h, risk_24h, risk_72h, flood_duration, rain_status, rain_qnty, segment_size;
        let acc_1h, avg_variation, last_3week_avg, acc_24h, style_risk_1h, style_risk_24h, style_risk_72h, trip_time_variation, last_3week_trip_time, density, density_variation, last_3week_density, acc_72h, flow_variation, last_3week_flow, spacing, spacing_variation, last_3week_spacing;
        let trend_1h, trend_24h, trend_72h, landslide_risk
        let events=[], cameras=[], data_bus_lines=[], support_points = [], new_events = [], past_events = [], alarm_siren = [];
        if (isLoading == false) {
            address = this.state.properties2.name;
            let separator = ', ';
            let lane = this.state.properties.segment.lane;
            if (lane) {
                address += separator + t('dashboard.details.segment.lane', {lane: lane});
            }
            let destination = this.state.properties.segment.destination;
            if (destination) {
                address += separator + t('dashboard.details.segment.destination', {destination: destination});
            }
            let reference = this.state.properties.segment.ref;
            if (reference) {
                address += ' ' + t('dashboard.details.segment.reference', {reference: reference});
            }
            // Mobility (Bus) Data
            // Indicador - Velocidade Média
            acc_1h = this.state.properties2.indicators['1h'].acc.toFixed(2)
            style_risk_1h = this.state.properties2.indicators['1h'].risk
            if(style_risk_1h == null){
                style_risk_1h= 'none'
            }
            risk_1h = this.state.properties2.indicators['1h'].trend
            if (risk_1h < 0) {
                trend_1h = "Tendência: ▼"
            } else if (risk_1h > 0){
                trend_1h = "Tendência: ▲"
            } else {
                trend_1h = "Tendência: -"
            }
            
            last_3week_avg = Math.ceil(this.state.properties.metrics.last_3week_avg);
            avg_variation = this.state.properties.metrics.avg_variation;
            bus_status = this.state.properties2.indicators.landslide['risk_class'];
            if (bus_status == null) {
                bus_status = 'none';
            }
            bus_status_color = styles[`risk_${bus_status}`];
            // Indicador - Tempo Médio (de Travessia)
            acc_24h = this.state.properties2.indicators['24h'].acc.toFixed(2)
            style_risk_24h = this.state.properties2.indicators['24h'].risk
            if(style_risk_24h == null){
                style_risk_24h= 'none'
            } 
            

            risk_24h = this.state.properties2.indicators['24h'].trend
            if (risk_24h < 0) {
                trend_24h = "Tendência: ▼"
            } else if (risk_24h > 0){
                trend_24h = "Tendência: ▲"
            } else {
                trend_24h = "Tendência: -"
            }
            bus_lines = this.state.properties.metrics.daily_bus_lines.map(item => {
                item = item.replace(/[^0-9\.]+/g,"");
                return Number(item)
            });
            trip_time_variation = this.state.properties.metrics.trip_time_variation;
            last_3week_trip_time = Math.ceil(this.state.properties.metrics.last_3week_trip_time);
            // Indicador - Densidade
            density = Math.ceil(this.state.properties.metrics.density);
            density_variation = this.state.properties.metrics.density_variation;
            last_3week_density = Math.ceil(this.state.properties.metrics.last_3week_density);
            // Indicador - Comprimento
            segment_size = parseFloat(this.state.properties.metrics.segment_size.toFixed(1)).toLocaleString();
            // Indicador - Fluxo
            acc_72h = this.state.properties2.indicators['72h'].acc.toFixed(2)
            style_risk_72h = this.state.properties2.indicators['72h'].risk
            if(style_risk_72h == null){
                style_risk_72h = 'none'
            } 

            risk_72h = this.state.properties2.indicators['72h'].trend
            if (risk_72h < 0) {
                trend_72h = "Tendência: ▼"
            } else if (risk_72h > 0){
                trend_72h = "Tendência: ▲"
            } else {
                trend_72h = "Tendência: -"
            }
            flow_variation = this.state.properties.metrics.flow_variation;
            last_3week_flow = Math.ceil(this.state.properties.metrics.last_3week_flow);
            // Indicador - Espaçamento
            spacing = Math.ceil(this.state.properties.metrics.spacing*1000); // Conversão de quilômetros para metros (x1000)
            spacing_variation = this.state.properties.metrics.spacing_variation;
            last_3week_spacing = Math.ceil(this.state.properties.metrics.last_3week_spacing*1000); // Conversão de quilômetros para metros

            support_points = this.state.properties2.details['support_points']
            new_events = this.state.properties2.events['new_events']
            past_events = this.state.properties2.events['past_events']
            landslide_risk = this.state.properties2.landslide_risk
            alarm_siren = this.state.properties2.details['alarm_siren']
            


            // Linhas de Ônibus no Trecho
            bus_lines_now = this.state.properties.metrics.bus_lines;
            let bus_lines_now_keys_array = Object.keys(bus_lines_now).map(item => {
                item = item.replace(/[^0-9\.]+/g,"");
                return Number(item)
            });
            let complete_bus_lines = bus_lines_now_keys_array.concat(bus_lines);
            complete_bus_lines = [...new Set([...bus_lines_now_keys_array,...bus_lines])].sort((a, b) => a - b)
            for (let line of complete_bus_lines) {
                data_bus_lines.push(this.renderBusLines({
                    line: line,
                    isActiveNow: bus_lines_now_keys_array.includes(line),
                    count: bus_lines_now[line],
                    //speed: 30,
                    //time: 5
                }))
            }
            // Câmeras Próximas
            this.state.properties.segment.cameras
            .sort((a, b) => (Number(a.properties.code) > Number(b.properties.code)) ? 1 : ((Number(b.properties.code) > Number(a.properties.code)) ? -1 : 0)) // Sort ordena pelo número das câmeras
            .forEach(element => { 
                cameras.push(this.renderCameras(element));
            });
            if (cameras.length == 0){
                cameras.push(this.renderItem('no_cameras', t('dashboard.details.info.cameras.no_cameras')));
            }
            // Ocorrências Próximas
            let events_array = this.state.properties.metrics.events;
            //events_array = [ {'properties': {'title': 'Obra na Via (RIO ÁGUAS)', 'description': 'Rua Batista da Costa - Alt. R. Jardim Botânico - Jardim Botânico - Sent. Av. Lineu de Paula Machado', 'start_datetime': '2020-11-12 08:05:29.000Z'}}] // Para teste apenas
            events_array.forEach(element => {
                events.push(this.renderEvents(element));
            });
            if (events.length == 0){
                events.push(this.renderItem('no_cameras', t('dashboard.details.events.no_events')));
            }
            // Alagamento
            risk_class = null
            flood_status = getFloodRisk(this.state.properties.metrics.risk_proba);
            flood_status_color = (risk_class == 'no_event') ? styles[`risk_low`] : styles[`risk_${flood_status}`];
            flood_duration = Math.ceil(this.state.properties.metrics.flood_duration);
            // Chuva
            rain_status = this.state.properties.metrics.rainfall_class;
            rain_qnty = typeof(this.state.properties.metrics.precipitation_acc1h) !== 'undefined' ? this.state.properties.metrics.precipitation_acc1h : '-';
        }

        return(
            <div className="dashboard-street__wrap">
                <Row className='justify-content-between'>
                    <Col md={1} onClick={() => this.sendData(false, [])} className='cursor-pointer'>
                        <span className="fa-layers fa-fw dashboard-regular-text">
                            <FontAwesomeIcon icon={faCircle} size='2x' transform="left-3" color={'#293535'} />
                            <FontAwesomeIcon icon={faChevronLeft} size='lg' transform="shrink-8" inverse />
                        </span>
                    </Col>
                    <Col md={10} className="dashboard-title p-4 ml-n5" style={{display: 'flex', alignItems: 'left'}}>
                        {!isLoading ? address : <Skeleton />}
                    </Col>
                    <Col md={1}/>
                    {/* <Col md={1} className='cursor-pointer' style={{textAlign: 'center'}} ref={(element) => {this.dropdownDetailMenu = element}} onClick={this.toggleMenu}>
                        <FontAwesomeIcon icon={faEllipsisV} size="xs" />
                    </Col> */}
                </Row>
                {this.state.showMenu ? this.renderDropDown() : null}
                <Row className='align-items-center'>
                    <Col md className='p-0'>
                        <hr className='my-0' />
                    </Col>
                </Row>
                
                <Row >
                    <Col md={12}>
                        <Row >
                            <Col md={4} className="cursor-pointer py-3" onClick={() => this.setState({selectedTab: 0})} style={{background: this.setActiveColor(0, bus_status_color)}}>
                                <Row className="dashboard-street-icon__wrap align-items-center">
                                    <FontAwesomeIcon icon={faTachometerAlt} size="sm" /> <span className="dashboard-street-icon ml-3" style={{fontSize: '2.2rem'}}>{t('dashboard.details.indicators')}</span>
                                </Row>
                            </Col>
                            <Col md={4} className="cursor-pointer py-3" onClick={() => this.setState({selectedTab: 1})} style={{background: this.setActiveColor(1, bus_status_color)}}>
                                <Row className="dashboard-street-icon__wrap align-items-center">
                                    <FontAwesomeIcon icon={faInfo} size="sm" /><span className="dashboard-street-icon ml-3" style={{fontSize: '2.2rem'}}>{t('dashboard.details.info.title')}</span>
                                </Row>
                            </Col>
                            <Col md={4} className="cursor-pointer py-3" onClick={() => this.setState({selectedTab: 2})} style={{background: this.setActiveColor(2, bus_status_color)}}>
                                <Row className="dashboard-street-icon__wrap align-items-center">
                                    <FontAwesomeIcon icon={faExclamationTriangle} size="sm" /> <span className="dashboard-street-icon ml-3" style={{fontSize: '2.2rem'}}>{t('dashboard.details.events.title')}</span>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mt-2 dashboard-street-details">
                            { (selectedTab == 1) ? 
                                (
                                    <>
                                        <Col md={6} className='pr-2'>
                                            <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-3" color={'#262d2d'} />
                                                        <FontAwesomeIcon icon={faHome} size='lg' transform="shrink-6 left-1" />
                                                    </span>
                                                </Col>
                                                <Col md={11} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t('dashboard.risks.bus_traffic.bus_lines.title') && 'Pontos de Apoio'}</span>
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-between px-4 dashboard-details-tab-item'>
                                                <Col md={12}>
                                                    <Row>
                                                        {/* {!isLoading ? data_bus_lines : <Skeleton width={500} height={'23vh'} />} */}
                                                        {(support_points.length != 0 && support_points != undefined) ? support_points.map(item => this.renderListItem(item)) : this.renderItens([])}
                                                        {/* {this.renderItem(2, 'Escola Estadual Augusto Meshick')}
                                                        {this.renderItem(3, 'Escola Municipal Governador Marcello Alencar')}
                                                        {this.renderItem(4, 'E.M. Rosalina Nicolay')} */}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6} className='pr-5'>
                                            <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-2" color={'#262d2d'} />
                                                        <FontAwesomeIcon icon={faVolumeUp} size='lg' transform="shrink-6" />
                                                    </span>
                                                </Col>
                                                <Col md={10} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t('dashboard.details.info.cameras.title') && 'Sirenes'}</span>
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-between px-4 dashboard-details-tab-item'>
                                                <Col md={{ span: 10, offset: 1 }}>
                                                    <Row>
                                                        {/* {!isLoading ? cameras : <Skeleton width={500} height={'23vh'} />} */}
                                                        {/* {this.renderItem('a', 'Dr. Thouzet')}
                                                        {this.renderItem('b', 'São Sebastião2-Adão Brand')}
                                                        {this.renderItem('c', '24 de Maio 2 - Morro do Estado')}
                                                        {this.renderItem('d', 'Vila Felipe2-Chácara Flora')} */}
                                                        {(alarm_siren.length != 0 && alarm_siren != undefined) ? alarm_siren.map(item => this.renderListItem(item)) : this.renderItens([])}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </>
                                )
                            : (selectedTab == 0) ?
                                (
                                    <>
                                        <Col md={6} className="dashboard-details-tab">
                                            <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-3" color={styles['risk_' + style_risk_1h]} />
                                                        <FontAwesomeIcon icon={faTint} size='lg' transform="shrink-6" />
                                                        <span className="fa-layers-counter" style={{background: styles.camera_icon_color, fontSize: '2em', opacity: 0.9, right: '-15px'}}>1h</span>
                                                    </span>
                                                </Col>
                                                <Col md={7} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{ t('dashboard.risks.bus_traffic.average_bus_speed.title')}</span>
                                                </Col>
                                                <Col md={4} style={{borderRadius: '50px', background: '#c4c4c4', color: '#353535', fontSize: '0.6em', textAlign: 'center'}} className='py-2'>
                                                    {t('dashboard.risks.bus_traffic.average_bus_speed.value', {value: acc_1h})}
                                                </Col>
                                                <Col md={12}>
                                                    {/* <span style={{fontSize: '1.2rem'}}>{t('dashboard.risks.bus_traffic.average_bus_speed.reference_value', {value: last_3week_avg})} ({avg_variation}%)</span> */}
                                                    <span style={{fontSize: '1.2rem'}}>{trend_1h}</span>
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-3" color={styles['risk_' + style_risk_24h]} />
                                                        <FontAwesomeIcon icon={faTint} size='lg' transform="shrink-6" />
                                                        <span className="fa-layers-counter" style={{background: styles.camera_icon_color, fontSize: '2em', opacity: 0.9, right: '-20px'}}>24h</span>
                                                    </span>
                                                </Col>
                                                <Col md={7} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t('dashboard.risks.bus_traffic.average_time_travel.title') && 'Acumulado 24h'}</span>
                                                </Col>
                                                <Col md={4} style={{borderRadius: '50px', background: '#c4c4c4', color: '#333d3d', fontSize: '0.6em', textAlign: 'center'}} className='py-2'>
                                                    {t('dashboard.risks.bus_traffic.average_time_travel.value', {time: acc_24h})}
                                                </Col>
                                                <Col md={12}>
                                                    {/* <span style={{fontSize: '1.2rem'}}>{t('dashboard.risks.bus_traffic.average_time_travel.reference_value', {value: last_3week_trip_time})} ({trip_time_variation}%)</span> */}
                                                    <span style={{fontSize: '1.2rem'}}>{trend_24h}</span>
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-3" color={styles['risk_' + style_risk_72h]} />
                                                        <FontAwesomeIcon icon={faTint} size='lg' transform="shrink-6" />
                                                        <span className="fa-layers-counter" style={{background: styles.camera_icon_color, fontSize: '2em', opacity: 0.9, right: '-20px'}}>72h</span>
                                                    </span>
                                                </Col>
                                                <Col md={7} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t('dashboard.risks.bus_traffic.flow.title' && 'Acumulado 72h')}</span>
                                                </Col>
                                                <Col md={4} style={{borderRadius: '50px', background: '#c4c4c4', color: '#333d3d', fontSize: '0.6em', textAlign: 'center'}} className='py-2'>
                                                    {t('dashboard.risks.bus_traffic.flow.value', {count: acc_72h})}
                                                </Col>
                                                <Col md={12}>
                                                    {/* <span style={{fontSize: '1.2rem'}}>{t('dashboard.risks.bus_traffic.flow.reference_value', {value: last_3week_flow})} ({flow_variation}%)</span> */}
                                                    <span style={{fontSize: '1.2rem'}}>{trend_72h}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6} className='pr-5 dashboard-details-tab'>
                                            <Row className='justify-content-between px-0 py-4'>
                                                <Col md={7} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t('dashboard.risks.bus_traffic.density.title') && 'Risco de Deslizamento'}</span>
                                                </Col>
                                                <Col md={5} style={{fontSize: '0.6em', textAlign: 'center'}} className='py-2'>
                                                    <Row className="justify-content-md-center">
                                                        <Col md={3} className="text-center" style={{fontSize: '0.6em', width: "100%"}}><img src={foursafeLogo} style={{width: '1em'}}/></Col>
                                                        <Col md={{ span: 3, offset: 1}} className="text-center" style={{fontSize: '0.6em', width: "100%"}}><img src={watertank} style={{width: '1.1em'}}/></Col>
                                                    </Row>
                                                </Col>
                                            </Row>

                                            {(landslide_risk != undefined &&  landslide_risk.length !== 0)  ? landslide_risk.map(array => this.setNeighborhoodsList(array)) : this.renderItens([])}
                                        </Col>
                                    </>
                                )
                            : 
                                (
                                    <>
                                        <Col md={6} className="dashboard-details-tab" style={{overflowY: 'hidden'}}>
                                            {/* <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-3" color={'#262d2d'} />
                                                        <FontAwesomeIcon icon={faCloudShowersHeavy} size='lg' transform="shrink-6" />
                                                    </span>
                                                </Col>
                                                <Col md={7} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t(`dashboard.risks.rain.status.${rain_status}`)}</span>
                                                </Col>
                                                <Col md={4} style={{borderRadius: '50px', background: '#c4c4c4', color: '#333d3d', fontSize: '0.6em', textAlign: 'center'}} className='py-2'>
                                                    {t(`dashboard.risks.rain.description`, {count: rain_qnty})}
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-3" color={'#262d2d'} />
                                                        <FontAwesomeIcon icon={faWater} size='lg' transform="shrink-6 left-2" />
                                                    </span>
                                                </Col>
                                                <Col md={7} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t(`dashboard.risks.flood.class.${risk_class}`)}</span>
                                                </Col>
                                                <Col md={4} style={{borderRadius: '50px', background: '#c4c4c4', color: '#333d3d', fontSize: '0.6em', textAlign: 'center'}} className='py-2'>
                                                    {(risk_class == 'no_event') ? '-' : t(`dashboard.risks.flood.status.${flood_status}`, {risk_class: `$t(dashboard.risks.flood.class.${risk_class})`})}
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-3" color={'#262d2d'} />
                                                        <FontAwesomeIcon icon={faWater} size='lg' transform="shrink-6 left-1" />
                                                    </span>
                                                </Col>
                                                <Col md={7} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t('dashboard.risks.flood.duration_forecast.title')}</span>
                                                </Col>
                                                <Col md={4} style={{borderRadius: '50px', background: '#c4c4c4', color: '#333d3d', fontSize: '0.6em', textAlign: 'center'}} className='py-2'>
                                                    {t('dashboard.risks.flood.duration_forecast.description', {context: risk_class, count: flood_duration})}
                                                </Col>
                                            </Row> */}
                                            <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-2" color={'#262d2d'} />
                                                        <FontAwesomeIcon icon={faExclamationTriangle} size='lg' transform="shrink-6" />
                                                    </span>
                                                </Col>
                                                <Col md={11} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t('dashboard.details.events.title') && 'Ocorrências Abertas'}</span>
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-between px-4 dashboard-details-tab'>
                                                <Col md={{ span: 10, offset: 1 }}>
                                                    <Row>
                                                        {/* {!isLoading ? events : <Skeleton width={500} height={'23vh'}/> }  */}
                                                        {/* {this.renderItem('none', 'Não há ocorrências abertas no momento.')} */}
                                                        {this.renderItens(past_events)} 
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6} className='pr-5'>
                                            <Row className='justify-content-between px-3 py-4'>
                                                <Col md={1}>
                                                    <span className="fa-layers fa-fw dashboard-regular-text">
                                                        <FontAwesomeIcon icon={faCircle} size='2x' transform="shrink-2 left-2" color={'#262d2d'} />
                                                        <FontAwesomeIcon icon={faExclamationTriangle} size='lg' transform="shrink-6" />
                                                    </span>
                                                </Col>
                                                <Col md={11} style={{display: 'flex', alignItems: 'center'}} className='pl-5'>
                                                    <span style={{fontSize: '1.8rem'}}>{t('dashboard.details.events.title') && 'Ocorrências Em Andamento'}</span>
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-between px-4 dashboard-details-tab'>
                                                <Col md={{ span: 10, offset: 1 }}>
                                                    <Row>
                                                        {/* {!isLoading ? events : <Skeleton width={500} height={'23vh'}/> }  */}
                                                        {/* {this.renderItem('o1', 'Exemplo da descrição da Ocorrência #1.')}
                                                        {this.renderItem('o2', 'Exemplo da descrição da Ocorrência #2.')}
                                                        {this.renderItem('o3', 'Exemplo da descrição da Ocorrência #3.')}
                                                        {this.renderItem('o4', 'Exemplo da descrição da Ocorrência #4.')} */}
                                                        {this.renderItens(past_events)} 
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </>
                                )
                            }
                        </Row>

                    </Col>
                </Row>
            </div>
        );
    }
}

class StreetComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpened: false,
            address: this.props.data.name,
            bus_status: null,
            acc_1h: this.props.data['1h'],
            acc_24h: this.props.data['24h'],
            acc_72h: this.props.data['72h'],
            risk_class: this.props.data.risk,
            events: [],
            average_velocity: null,
            number_of_vehicles: null,
            density: null,
            flood_status: null,
            last_updated: null,
            segment_id: this.props.data.segment_id
        }
    }

    sendData() {
        this.props.parentCallback(this.props.segment_id);
    }

    handleOpenCloseClick() {
        this.setState(prevState => ({
            isOpened: !prevState.isOpened
        }));
    }

    render () {
        const { t } = this.props;
        //let time = this.state.last_updated;
        let address = this.state.address;
        let acc_1h = (this.state.acc_1h == null) ? '-' : Math.round(this.state.acc_1h*100)/100;
        let acc_24h = (this.state.acc_24h == null) ? '-' : Math.round(this.state.acc_24h*100)/100;
        let acc_72h = (this.state.acc_72h == null) ? '-' : Math.round(this.state.acc_72h*100)/100;
        let bus_status = this.state.bus_status;
        if (bus_status == null) {
            bus_status = 'normal';
        }
        let events = this.state.events;
        let risk_class = this.state.risk_class;
        //risk_class = Math.random() > 0.5 ? 'pocket' : 'no_event'; // Esta linha não deve ser commitada. Ela apenas auxilia nos testes.
        let show_flood = risk_class != 'no_event';
        let flood_status = this.state.flood_status;
        let flood_status_color = (risk_class == 'no_event') ? styles[`risk_low`] : styles[`risk_${risk_class}`];

        let show_event = false;
        let event_color = styles[`risk_${risk_class}`];
        let bus_status_color = risk_class !== null ? styles[`risk_${risk_class}`] : styles['none']
        let event_text = null;
        let event_icon = faExclamationTriangle;
        // if (events.length > 0) {
        //     show_event = true;
        //     events = events[0];
        //     event_text = events.properties.title;
        // } else if (show_flood) {
        //     show_event = true;
        //     event_text = (risk_class == 'no_event') ? t(`$t(dashboard.risks.flood.class.${risk_class})`) : t(`dashboard.risks.flood.status.${flood_status}`, {risk_class: `$t(dashboard.risks.flood.class.${risk_class})`});
        //     event_color = styles['none'];
        //     event_icon = faWater;
        // }
        let isOpened = this.state.isOpened;
        //let flood_duration = (risk_class == 'no_event') ? '-' : flood_duration;
        let average_velocity = Math.ceil(this.state.average_velocity);
        let number_of_vehicles = this.state.number_of_vehicles;
        let density = this.state.density;

        return (
            <div className="dashboard-street__wrap cursor-pointer pl-0 mx-0" onClick={() => this.sendData()}>
            {/* <div className="dashboard-street__wrap cursor-pointer pl-0 mx-0"> */}

                <div className="cursor-pointer d-flex flex-row align-content-around align-content-center">
                    
                    <div className='px-4 py-2 bd-highlight' style={{background: bus_status_color, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '28px', width: '5%'}}>
                        {/* <FontAwesomeIcon icon={faMountain} size='sm' inverse /> */}
                        <img src={LandslideRiskLogo} alt="Landslide Logo" style={{width: '4.3rem'}} />
                    </div>
                    <div className='px-4 py-2 bd-highlight flex-grow-1' style={{background: '#374141', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '35%'}}>
                        <span style={{fontSize: '0.7em'}} >{address}</span>
                    </div>
                    <div className='px-4 py-2 mr-2 bd-highlight' style={{background: '#4c5959', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '15%'}}>
                        <span style={{fontSize: '0.6em', fontWeight: 'bold'}}>{acc_1h} mm</span>
                        <span style={{fontSize: '0.4em'}}>Acumulado 1h</span>
                    </div>
                    <div className='px-4 py-2 mr-2 bd-highlight' style={{background: '#4c5959', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '15%'}}>
                        <span style={{fontSize: '0.6em', fontWeight: 'bold'}}>{acc_24h} mm</span>
                        <span style={{fontSize: '0.4em'}}>Acumulado 24h</span>
                    </div>
                    <div className='px-4 py-2 bd-highlight' style={{background: '#4c5959', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '17%'}}>
                        <span style={{fontSize: '0.6em', fontWeight: 'bold'}}>{acc_72h} mm</span>
                        <span style={{fontSize: '0.4em'}}>Acumulado 72h</span>
                    </div>
                    
                    <div className='p-2 bd-highlight' style={{background: '#374141', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '7%'}}>
                        <span className="fa-layers fa-fw dashboard-regular-text">
                            <FontAwesomeIcon icon={faCircle} size='2x' transform="left-3" color={'#293535'} />
                            <FontAwesomeIcon icon={faChevronRight} size='lg' transform="shrink-8" inverse />
                        </span>   
                    </div>

                </div>

            </div>
        );
    }
}



class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showMenu: false,
            detailView: {
                isLoading: true,
                status: false,
                segment_id: null,
                name: null
            },
            isLoading: true,
            timestamp: null,
            last_modified: null,
            mobilityData: [],
            vision: this.props.vision
        };

        this.callbackFunction = this.callbackFunction.bind(this);
        this.returnFunction = this.returnFunction.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    componentDidMount() {
        // Recarregar no intervalo definido (em milissegundos)
        this.interval = setInterval(() => {
            this.setState({ time: Date.now() });
            this.getMobilityData();
            console.log('Dashboard reloaded at ', new Date().toLocaleString());
        }, RELOAD_TIME);
        this.getMobilityData();
        document.addEventListener("click", this.handleClickOutside);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        document.removeEventListener("click", this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {

        const getData = async () => {
            const url = (this.props.vision == 'distritos') ? 'https://dev.foursafe.com/api/urbii//maps/districts/info' :  'https://dev.foursafe.com/api/urbii/maps/boundaries'
            const data = await getIndicator(url)
            if (this.props.vision != prevProps.vision) {
                let vision = (this.props.vision == 'distritos') ? 'distritos' : 'microbacias';
                this.setState({
                    vision: vision,
                    mobilityData: data.data,
                    timestamp: data.timestamp,
                    last_modified: data.timestamp,
                    isLoading: false
                });
            }
        }

        getData()
       
    }

    async getMobilityData() {
        this.setState({
            isLoading: true
        });

        const url = (this.props.vision == 'distritos') ? 'https://dev.foursafe.com/api/urbii//maps/districts/info' :  'https://dev.foursafe.com/api/urbii/maps/boundaries'
        const data = await getIndicator(url)
        
        this.setState({
            mobilityData: data.data,
            timestamp: data.timestamp,
            last_modified: data.timestamp,
            isLoading: false
        });
    }

    sendData = (e) => {
        this.props.parentCallback(e);
    }

    callbackFunction(segment_id) {
        this.sendData(segment_id);
        this.setState({
            detailView: {
                status: !this.state.detailView.status,
                segment_id: segment_id
            }
        });
    }

    returnFunction(status, cameras_layer) {
        if (!status) {
            this.sendData(RESET_VIEW_CMD);
            this.setState({
                detailView: {
                    status: status,
                    segment_id: null
                }
            });
        } else {
            this.sendCamerasLayer(cameras_layer);
        }
    }

    sendCamerasLayer = cameras_layer => {
        this.props.camerasLayerCallback(cameras_layer);
    }

    toggleMenu(event) {
        event.preventDefault();
        this.setState({
            showMenu: !this.state.showMenu,
        });
    }

    handleClickOutside = event => {
        event.preventDefault();
        if (this.dropdownMenu && !this.dropdownMenu.contains(event.target)){
            this.setState({
                showMenu: false
            });
        }
    }

    copyRoads = event => {
        const { t } = this.props;
        let data = this.state.mobilityData;
        let isLoading = this.state.isLoading;
        this.toggleMenu(event);


        if (isLoading) {
            toast.error(t('messages.no_data_copy'), TOASTS_CONFIG);
            return
        }

        let timestamp = this.state.last_modified;
        let date = new Date(timestamp*1000).toLocaleDateString();
        let time = new Date(timestamp*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});

        let text = '🚌 ';
        text += `${t('dashboard.title').toUpperCase()}\n`; // Título da mensagem
        text += `${t('dashboard.source')}\n`;
        text += `${t('map.subtitle', {date: date, time: time})}\n\n`;

        if (data.length == 0) {
            text += `${t('dashboard.no_roads')}`;
        } else {
            data.forEach((segment, index) => {
                let address = segment.name;
                let separator = ', ';
                let lane = segment.segment.lane;
                if (lane) {
                    address += separator + t('dashboard.details.segment.lane', {lane: lane});
                }
                let destination = segment.segment.destination;
                if (destination) {
                    address += separator + t('dashboard.details.segment.destination', {destination: destination});
                }
                let reference = segment.segment.ref;
                if (reference) {
                    address += ' ' + t('dashboard.details.segment.reference', {reference: reference});
                }
                let segment_size = parseFloat(segment.metrics.segment_size.toFixed(1)).toLocaleString();
                text += `${address} - ${segment_size} km - ${t('dashboard.risks.bus_traffic.average_time_travel.value', {time: Math.ceil(segment.metrics.trip_time)})} - ${t('dashboard.risks.bus_traffic.average_bus_speed.value', {value: Math.ceil(segment.metrics.average_velocity)})} ${getVariationText(segment.metrics.avg_variation, 'speed')}\n\n`;
            })
            text = text.slice(0, -1); // Remover último '\n'
        }

        let blob = new Blob([text], {type: 'text/plain'});
        const item = new ClipboardItem({'text/plain': blob});
        navigator.clipboard.write([item]);

        toast.success(t('messages.copy_roads_success'), TOASTS_CONFIG);
    }

    copyBusLines = event => {
        const { t } = this.props;

        getTrafficBusLines()
        .then(bus_lines => {
            let data = bus_lines.data;
            let timestamp = bus_lines.timestamp;
            if (data == []) {
                throw new Error('No data to show');
            }

            let date = new Date(timestamp*1000).toLocaleDateString();
            let time = new Date(timestamp*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    
            let text = '🚌 ';
            text += `${t('dashboard.risks.bus_traffic.bus_lines.copy_title').toUpperCase()}\n`; // Título da mensagem
            text += `${t('dashboard.source')}\n`;
            text += `${t('map.subtitle', {date: date, time: time})}\n\n`;

            if (data.length == 0) {
                text += `${t('dashboard.no_bus_lines')}`;
            } else {
                data.forEach((segment) => {
                    let address = segment.name;
                    let separator = ', ';
                    let lane = null;
                    if (lane) {
                        address += separator + t('dashboard.details.segment.lane', {lane: lane});
                    }
                    let destination = null;
                    if (destination) {
                        address += separator + t('dashboard.details.segment.destination', {destination: destination});
                    }
                    let reference = null;
                    if (reference) {
                        address += ' ' + t('dashboard.details.segment.reference', {reference: reference});
                    }
                    let bus_lines = Object.keys(segment.metrics.bus_lines).map(a => `${a}`).join(', ');

                    text += `${address}\n${t('dashboard.risks.bus_traffic.bus_lines.title')}: ${bus_lines}\n\n`;
                })
                text = text.slice(0, -2); // Remover 2 últimos '\n'
            }
    
            let blob = new Blob([text], {type: 'text/plain'});
            const item = new ClipboardItem({'text/plain': blob});
            navigator.clipboard.write([item]);
    
            toast.success(t('messages.copy_bus_lines_success'), TOASTS_CONFIG);
        })
        .catch( e => {
            toast.error(t('messages.no_data_copy'), TOASTS_CONFIG)
        })
        this.toggleMenu(event);
    }

    renderDropDown() {
        const { t } = this.props;
        return (
            <Row className="" style={{position: 'absolute', top: '9%', right: '5%', backgroundColor: '#1f2727', borderRadius: '5px', minWidth: '100px', boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)', zIndex: 1, fontSize: '1.3rem'}}>
                <Col>
                    <Row>
                        <Col md={12} className="dropdown-option p-4 cursor-pointer dot-menu-item" onClick={e => this.exportMap(e)}>{t('dashboard.dropdown.export_map')}</Col>
                    </Row>
                    <Row>
                        <Col md={12} className='p-0'><hr className='my-0' /></Col>
                    </Row>
                    <Row>
                        <Col md={12} className="dropdown-option p-4 cursor-pointer dot-menu-item" onClick={e => this.copyMap(e)}>{t('dashboard.dropdown.copy_map')}</Col>
                    </Row>
                    <Row>
                        <Col md={12} className='p-0'><hr className='my-0' /></Col>
                    </Row>
                    <Row>
                        <Col md={12} className="dropdown-option p-4 cursor-pointer dot-menu-item" onClick={e => this.copyRoads(e)}>{t('dashboard.dropdown.copy_roads')}</Col>
                    </Row>
                    <Row>
                        <Col md={12} className='p-0'><hr className='my-0' /></Col>
                    </Row>
                    <Row>
                        <Col md={12} className="dropdown-option p-4 cursor-pointer dot-menu-item" onClick={e => this.copyBusLines(e)}>{t('dashboard.dropdown.copy_bus_lines')}</Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    exportMap = () => {
        let timestamp = new Date();
        let date = new Date(timestamp).toLocaleDateString().replaceAll('/','-');
        let time = new Date(timestamp).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}).replaceAll(':','-');
        let div_element = document.getElementById("map");
        const options = {
            allowTaint: true,
            useCORS: true,
            backgroundColor :'#1a2323',
            onrendered: function(canvas) {
                div_element.appendChild(canvas);
            }
        }
        html2canvas(div_element, options).then(canvas => {
            canvas.toBlob(blob => {
                saveAs(blob, "traffic_map_" + date + '_' + time + ".png");
            });
        });
    };

    copyMap = (event) => {
        const { t } = this.props;
        let div_element = document.getElementById("map");
        const options = {
            allowTaint: true,
            useCORS: true,
            backgroundColor :'#1a2323',
            onrendered: function(canvas) {
                div_element.appendChild(canvas);
            }
        }
        this.toggleMenu(event);
        html2canvas(div_element, options).then(canvas => {
            canvas.toBlob(blob => {
                const item = new ClipboardItem({"image/png": blob});
                navigator.clipboard.write([item]); 
            });
        }).then(
            toast.success(t('messages.copy_map_success'), {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        );
    };

    renderItem(id, text) {
        return (
            <Container className="dashboard-street__wrap cursor-pointer pl-0">
                <Row key={id}>
                    <Col md={12}>
                        <span className="" style={{fontSize: '1.9vh'}}>{text}</span>
                    </Col>
                </Row>
            </Container>
        )
    }

    render() {
        const { t } = this.props;
        let style = this.props.style;
        let isLoading = this.state.isLoading;
        let isDetailView = this.state.detailView.status;
        let mobilityData = this.state.mobilityData;
        let streetArray = [];
        if (mobilityData.length > 0) {
            for (let item of mobilityData) {
                streetArray.push(<Street key={item.id} segment_id={item.id} name={item.name} data={item} parentCallback={this.callbackFunction} />);
            }
        } else {
            streetArray = this.renderItem('no_roads', t('dashboard.no_roads'));
        }
        
        return (
            <>
                {this.state.showMenu ? this.renderDropDown() : null}
                <Suspense fallback="loading">
                    {
                        isDetailView ? 
                        (
                            (this.state.vision == 'distritos') ? 
                            <DistritoDetailPage segment_id={this.state.detailView.segment_id} timestamp={this.state.timestamp} parentCallback={this.returnFunction}/>
                            :
                            <DetailPage segment_id={this.state.detailView.segment_id} name={this.state.detailView.name}  timestamp={this.state.timestamp} parentCallback={this.returnFunction}/>
                        )
                        :
                        (   
                            <>
                                <Row className='justify-content-between'>
                                    <Col md={'auto'} className="dashboard-title p-4">
                                        {t('dashboard.title')}
                                    </Col>
                                    {/* <Col md={1} className='cursor-pointer' style={{textAlign: 'center'}} ref={(element) => {this.dropdownMenu = element}} onClick={this.toggleMenu}>
                                        <FontAwesomeIcon icon={faEllipsisV} size="xs" />
                                    </Col> */}
                                </Row>
                                
                                <Row className='align-items-center pb-2'>
                                    <Col md className='p-0'>
                                        <hr className='my-0' />
                                    </Col>
                                </Row>
                                
                                <div className="dashboard-streets__wrap">
                                    {
                                        !isLoading ?
                                        streetArray
                                        :
                                        Array(5)
                                            .fill()
                                            .map((item, index) => (
                                                <SkeletonStreet key={index}/>
                                            ))
                                    }
                                </div>
                            </>
                        )
                    }
                </Suspense>
            </>
        );
    }
}

const DetailPage = withTranslation()(DetailComponent);
const DistritoDetailPage = withTranslation()(DistritoComponent);
const Street = withTranslation()(StreetComponent);
export default withTranslation()(Dashboard);
import React, { Suspense } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Map, TileLayer, GeoJSON, ImageOverlay, LayersControl, Marker  } from "react-leaflet";
import { Row, Col } from 'react-bootstrap';
import MarkerClusterGroup from "react-leaflet-markercluster";
import Control from 'react-leaflet-control';
import { divIcon } from "leaflet";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Button } from 'react-bootstrap';
import { withTranslation, Trans } from 'react-i18next';
import hash from 'object-hash';
import { getBusTrafficMap, getFloodMap, getGifRadar, getIndicator } from '../utils/apis';
import { FLOOD_CLASS, getFloodRisk } from '../utils/risks';

import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { toast } from 'react-toastify';

import { TOASTS_CONFIG, RELOAD_TIME, DEFAULT_BOUNDS, CAMERA_ZOOM_LEVEL, TILELAYER_TEMPLATE, TILELAYER_TEMPLATE_VIDEOWALL, RESET_VIEW_CMD, URL_GIF } from '../utils/config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faExclamationCircle, faEllipsisV, faHome, faTint, faCircle, faWater, faVideo, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import simple_logo from '../../img/logo-img.svg';

import pontos_apoio from '../../data/petropolis/pontos_apoio_petropolis.json';
import estacoes from '../../data/petropolis/estacoes.json';
import areas_risco from '../../data/petropolis/areas_risco_quitandinha.json';
import { DETAILS } from '../../data/petropolis/mock_city_data';

import styles from '../../css/style.scss';
import { config } from '@fortawesome/fontawesome-svg-core';
import { latLng } from 'leaflet';

let segmentsById = {};
let actual_bounds = [];


const camera_icon = new divIcon({
    html: ReactDOMServer.renderToString(
        <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon={faCircle} size="2x" color={styles.camera_icon_color} />
            <FontAwesomeIcon icon={faVideo} size='2x' transform="shrink-9 left-1" inverse />
        </span>
    ),
    iconSize: [25, 55],
    className: 'dummy'
});

const apoio_icon = new divIcon({
    html: ReactDOMServer.renderToString(
        <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon={faCircle} size="1x" color={'#68BBE3'} />
            <FontAwesomeIcon icon={faHome} size='1x' transform="shrink-9" inverse />
        </span>
    ),
    iconSize: [25, 55],
    className: 'dummy'
});

const siren_icon = new divIcon({
    html: ReactDOMServer.renderToString(
        <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon={faCircle} size="1x" color={'#055C9D'} />
            <FontAwesomeIcon icon={faVolumeUp} size='1x' transform="shrink-9" inverse />
        </span>
    ),
    iconSize: [25, 55],
    className: 'dummy'
});

const flu_icon = new divIcon({
    html: ReactDOMServer.renderToString(
        <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon={faCircle} size="1x" color={'#0E86D4'} />
            <FontAwesomeIcon icon={faWater} size='1x' transform="shrink-9" inverse />
        </span>
    ),
    iconSize: [25, 55],
    className: 'dummy'
});

const station_icon = new divIcon({
    html: ReactDOMServer.renderToString(
        <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon={faCircle} size="1x" color={'#003060'} />
            <FontAwesomeIcon icon={faTint} size='1x' transform="shrink-9" inverse />
        </span>
    ),
    iconSize: [25, 55],
    className: 'dummy'
});

const getFloodIcon = (type, risk) => {
    return new divIcon({
        html: ReactDOMServer.renderToString(
            <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={faCircle} size="2x" color={styles[`risk_${risk}`]} />
                <FontAwesomeIcon icon={faWater} size='2x' transform="shrink-8 left-1" inverse />
            </span>
        ),
        iconSize: [25, 55],
        className: 'dummy'
    });
}

const ocorrencias_icon = new divIcon({
    html: ReactDOMServer.renderToString(
        <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon={faCircle} size="1x" color={'#1a2323'} />
            <FontAwesomeIcon icon={faExclamationTriangle} size='1x' transform="shrink-7 up-1" color='orange'/>
        </span>
    ),
    iconSize: [25, 55],
    className: 'dummy'
});

class MapWrap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDataLoading: true,
            isTileLoading: true,
            segment_id: this.props.segment_id,
            isCameraShow: false,
            isApoiosShow: false,
            isPluviometrosShow: false,
            isFluviometrosShow: false,
            isSirenesShow: false,
            isRadarShow: false,
            isOccurrencesShow: false,
            radarData: null,
            floodData: [],
            mobilityData: [],
            bounds: DEFAULT_BOUNDS,
            timestamp: null,
            cameras_layer: this.props.cameras_layer,
            showMenu: false,
            base_layer_type: null
        }

        this.style = this.style.bind(this);
        this.style_subbacia = this.style_subbacia.bind(this);
        this.handleZoom = this.handleZoom.bind(this);
        this.goToPoint = this.goToPoint.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    componentDidMount() {
        // Recarregar no intervalo definido (em milissegundos)
        this.interval = setInterval(() => {
            this.setState({ 
                time: Date.now(),
                isDataLoading: true
             });
            this.getMobilityData();
            this.getRadarData()
            console.log('Map reloaded at ', new Date().toLocaleString());
        }, RELOAD_TIME);
        this.getMobilityData();
        this.getRadarData();
        document.addEventListener("click", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside);
        clearInterval(this.interval);
    }

    sendData = (e) => {
        this.props.parentCallback(e);
    }

    async getMobilityData() {

        const events = await getIndicator('https://dev.foursafe.com/api/urbii/events/all')
        const districts = await getIndicator('https://dev.foursafe.com/api/urbii/maps/districts')
        const subbacias = await getIndicator('https://dev.foursafe.com/api/urbii/maps/3303906')
        const neighborhoods = await getIndicator('https://dev.foursafe.com/api/urbii/maps/neighborhoods')
        const riskAreas = await(getIndicator('https://dev.foursafe.com/api/urbii/maps/risk_areas'))

        let geosjon_road_segments_map = subbacias;

        if (geosjon_road_segments_map.features.length > 0) {
            let bounds = L.geoJSON(geosjon_road_segments_map).getBounds();
            actual_bounds = [
                [bounds.getNorthEast().lat, bounds.getNorthEast().lng],
                [bounds.getSouthWest().lat, bounds.getSouthWest().lng]
            ];
        } else {
            actual_bounds = DEFAULT_BOUNDS;
        }
        this.setState({
            events: events.data,
            districts: districts,
            neighborhoods: neighborhoods,
            riskAreas: riskAreas,            

            mobilityData: geosjon_road_segments_map.features,
            timestamp: geosjon_road_segments_map.timestamp,
            bounds: actual_bounds
        });
        this.setState({
            isDataLoading: false
        })
    }

    async getRadarData() {
        const radar_image = await getGifRadar('https://dev.foursafe.com/api/urbii/get/radarImage/10')
        const bounds = [
            [parseFloat(-18.828), parseFloat(-47.2829)],
            [parseFloat(-26.048384), parseFloat(-39.30158)]
        ]
        this.setState({
            radarData: {
                image: radar_image,
                bounds: bounds
            },
            isDataLoading: false
        })
    }

    exportMap = () => {
        let timestamp = new Date();
        let date = new Date(timestamp).toLocaleDateString().replaceAll('/','-');
        let time = new Date(timestamp).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}).replaceAll(':','-');
        let div_element = document.getElementById("map_container");
        const options = {
            allowTaint: true,
            useCORS: true,
            backgroundColor :'#1a2323',
            onrendered: function(canvas) {
                div_element.appendChild(canvas);
            }
        }
        html2canvas(div_element, options).then(canvas => {
            canvas.toBlob(blob => {
                saveAs(blob, "traffic_map_" + date + '_' + time + ".png");
            });
        });
    };

    copyMap = (event) => {
        const { t } = this.props;
        let div_element = document.getElementById("map_container");
        const options = {
            allowTaint: true,
            useCORS: true,
            backgroundColor :'#1a2323',
            onrendered: function(canvas) {
                div_element.appendChild(canvas);
            }
        }
        this.toggleMenu(event);
        html2canvas(div_element, options).then(canvas => {
            canvas.toBlob(blob => {
                const item = new ClipboardItem({"image/png": blob});
                navigator.clipboard.write([item]); 
            });
        }).then(
            toast.success(t('messages.copy_map_success'), {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        );
    };

    style(feature) {
        return {
            color : '#727777',
            // TODO: Mudar para isso para o que está na linha de baixo
            // color: (feature.properties.risk !== null) ? styles[`risk_${feature.properties.risk}`] : styles['risk_none']
            weight: 1,
            opacity: 0.8,
            fillOpacity: 0.5
        };
    };

    styleRisco(feature) {
        return {
            color: (feature.properties.risk !== null) ? styles[`risk_${feature.properties.risk}`] : styles['risk_none'],
            // TODO : Mudar para isso para o que está na linha de baixo
            // color: (feature.properties.risk !== null) ? styles[`risk_${feature.properties.risk}`] : styles['risk_none'],
            weight: 1,
            opacity: 0.8,
            fillOpacity: 0.5
        };
    };

    create_cluster(data){

        let markersVeryHigh = L.markerClusterGroup({
            iconCreateFunction: function(cluster) {
                var icon = markersVeryHigh._defaultIconCreateFunction(cluster);
                icon.options.className += ' risk-very-high';
                return icon;
            }
        });

        let markersHigh = L.markerClusterGroup({
            iconCreateFunction: function(cluster) {
                var icon = markersHigh._defaultIconCreateFunction(cluster);
                icon.options.className += ' risk-high';
                return icon;
            }
        });

        let markersMedium = L.markerClusterGroup({
            iconCreateFunction: function(cluster) {
                var icon = markersMedium._defaultIconCreateFunction(cluster);
                icon.options.className += ' risk-medium';
                return icon;
            }
        });

        let markersLow = L.markerClusterGroup({
            iconCreateFunction: function(cluster) {
                var icon = markersLow._defaultIconCreateFunction(cluster);
                icon.options.className += ' risk-low';
                return icon;
            }
        });

        let markersNone = L.markerClusterGroup({
            iconCreateFunction: function(cluster) {
                var icon = markersNone._defaultIconCreateFunction(cluster);
                icon.options.className += ' risk-none';
                return icon;
            }
        });

        const redPin = new divIcon({
            html: ReactDOMServer.renderToString(
                <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faCircle} size="1x" color={'#e52e2e'} />
                    <FontAwesomeIcon icon={faExclamationTriangle} size='1x' transform="shrink-7 up-1" color='black'/>
                </span>
            ),
            iconSize: [25, 55],
            className: 'dummy'
        });
        
        const orangePin = new divIcon({
            html: ReactDOMServer.renderToString(
                <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faCircle} size="1x" color={'#ff5700'} />
                    <FontAwesomeIcon icon={faExclamationTriangle} size='1x' transform="shrink-7 up-1" color='black'/>
                </span>
            ),
            iconSize: [25, 55],
            className: 'dummy'
        });
        
        const yellowPin = new divIcon({
            html: ReactDOMServer.renderToString(
                <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faCircle} size="1x" color={'#FFB81C'} />
                    <FontAwesomeIcon icon={faExclamationTriangle} size='1x' transform="shrink-7 up-1" color='black'/>
                </span>
            ),
            iconSize: [25, 55],
            className: 'dummy'
        });
        

        const greenPin = new divIcon({
            html: ReactDOMServer.renderToString(
                <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faCircle} size="1x" color={'#00b94b'} />
                    <FontAwesomeIcon icon={faExclamationTriangle} size='1x' transform="shrink-7 up-1" color='black'/>
                </span>
            ),
            iconSize: [25, 55],
            className: 'dummy'
        });
        


        // const redPin = L.icon({
        //     iconUrl: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|e52e2e&chf=a,s,ee00FFFF'
        // })

        // const orangePin = L.icon({
        //     iconUrl: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ff5700&chf=a,s,ee00FFFF'
        // })

        // const yellowPin = L.icon({
        //     iconUrl: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FFB81C&chf=a,s,ee00FFFF'
        // })

        // const greenPin = L.icon({
        //     iconUrl: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|00b94b&chf=a,s,ee00FFFF'
        // })


        markersVeryHigh.clearLayers();

        markersHigh.clearLayers();

        markersMedium.clearLayers();

        markersLow.clearLayers();

        markersNone.clearLayers();

        for(let item of data){
            if(item.properties.severity === 'very_high'){
                markersVeryHigh.addLayer(L.marker(item.geometry.coordinates.reverse(), {icon: redPin}).bindPopup(`Tipo de Ocorrência - ${item.properties.parent_occurrence_type}<br> Estágio da Ocorrência - ${item.properties.occurrence_state}<br> Dia do Registro - ${new Date(item.properties.attendance_datetime).toLocaleDateString()}<br> ${item.properties.logradouro}, ${item.properties.numero} - ${item.properties.neighborhood}<br> Número RO - ${item.properties.source_id}`))
            } else if (item.properties.severity === 'high') {
                markersHigh.addLayer(L.marker(item.geometry.coordinates.reverse(), {icon: orangePin}).bindPopup(`Tipo de Ocorrência - ${item.properties.parent_occurrence_type}<br> Estágio da Ocorrência - ${item.properties.occurrence_state}<br> Dia do Registro - ${new Date(item.properties.attendance_datetime).toLocaleDateString()}<br> ${item.properties.logradouro}, ${item.properties.numero} - ${item.properties.neighborhood}<br> Número RO -  ${item.properties.source_id}`))
            } else if (item.properties.severity === 'medium') {
                markersMedium.addLayer(L.marker(item.geometry.coordinates.reverse(), {icon: yellowPin}).bindPopup(`Tipo de Ocorrência - ${item.properties.parent_occurrence_type}<br> Estágio da Ocorrência - ${item.properties.occurrence_state}<br> Dia do Registro - ${new Date(item.properties.attendance_datetime).toLocaleDateString()}<br> ${item.properties.logradouro}, ${item.properties.numero} - ${item.properties.neighborhood}<br> Número RO -  ${item.properties.source_id}`))
            } else if (item.properties.severity === 'low') {
                markersLow.addLayer(L.marker(item.geometry.coordinates.reverse(), {icon: greenPin}).bindPopup(`Tipo de Ocorrência - ${item.properties.parent_occurrence_type}<br> Estágio da Ocorrência - ${item.properties.occurrence_state}<br> Dia do Registro - ${new Date(item.properties.attendance_datetime).toLocaleDateString()}<br> ${item.properties.logradouro}, ${item.properties.numero} - ${item.properties.neighborhood}<br> Número RO -  ${item.properties.source_id}`))
            } else {
                markersNone.addLayer(L.marker(item.geometry.coordinates.reverse()))
            }
        }

        this.refs.map.leafletElement.addLayer(markersVeryHigh)
        this.refs.map.leafletElement.addLayer(markersHigh)
        this.refs.map.leafletElement.addLayer(markersMedium)
        this.refs.map.leafletElement.addLayer(markersLow)
        this.refs.map.leafletElement.addLayer(markersNone)


    }

    style_subbacia(feature) {
        return {
            color: (feature.properties.risk !== null) ? styles[`risk_${feature.properties.risk}`] : styles['risk_none'],
            weight: 1,
            opacity: 0.8,
            fillOpacity: 0.5
        };
    };

    handleZoom(event) {
        let zoom = this.refs.map.leafletElement.getZoom();
        let isCameraShow = false;
        (zoom >= CAMERA_ZOOM_LEVEL) && (isCameraShow = true);
        (this.state.isCameraShow != isCameraShow) && this.setState({isCameraShow: isCameraShow});
    };

    goToPoint(bounds) {
        let leafletMap = this.refs.map.leafletElement;
        let padding = [0, 0];
        let default_bounds = [[-22.202493758999918, -42.977917430999476], [-22.545469410034613, -43.37712572337802]];
        let isReturn = false;
        if (bounds && (bounds.length > 0)) {
            isReturn = (bounds[0][0] == default_bounds[0][0]) && (bounds[0][1] == default_bounds[0][1]) && ((bounds[1][0] == default_bounds[1][0])) && (bounds[1][1] == default_bounds[1][1]);
        }
        if ((this.state.base_layer_type == 'distritos') && (isReturn == false)) {
            padding = [-500, -500];
        }
        leafletMap.fitBounds(bounds, {animate: true});
    };

    componentDidUpdate(prevProps) {
        if (this.props.segment_id != prevProps.segment_id) {
            let bounds = this._getBounds(this.props.segment_id);
            this.goToPoint(bounds);
        }
        if (this.props.cameras_layer != prevProps.cameras_layer) {
            this.setState({
                cameras_layer: this.props.cameras_layer
            });
        }
        this.handleZoom();
    }

    onEachFloodFeature = (feature, layer) => {
        layer.bindPopup(ReactDOMServer.renderToString(
            this._eventModal(feature.properties.flood_class, feature.properties.duration)
        ));
    }

    onEachCameraFeature = (feature, layer) => {
        let camera_number = feature.properties.code;
        let camera_description = feature.properties.POI;
        layer.bindPopup(ReactDOMServer.renderToString(
            this._cameraModal(camera_number, camera_description)
        ));
    }

    onEachApoioFeature = (feature, layer) => {
        let camera_number = feature.properties.POI;
        let camera_description = feature.properties.address;
        layer.bindPopup(ReactDOMServer.renderToString(
            this._cameraModal(camera_number, camera_description)
        ));
    }

    onEachStationFeature = (feature, layer) => {
        let camera_number = feature.properties.ESTAÇÕES;
        let camera_description =  feature.properties.orgao;
        layer.bindPopup(ReactDOMServer.renderToString(
            this._cameraModal(camera_number, camera_description)
        ));
    }

    onEachSegmentFeature = (feature, layer) => {
        // Save the layer into segmentsById if it has an id.
        if (feature.properties.CLASSE) {
            segmentsById[feature.properties.CLASSE] = layer;
        }

        if (feature.properties && feature.properties.bairro) {
            layer.bindTooltip(
                "<div style='font-size: 1.5rem; background:'none'; padding:1px 3px 1px 3px'>"+feature.properties.bairro+"</div>",
                {
                    permanent: false, 
                    direction:"center",
                    opacity: 0.8
                }
            );
        }
    }

    _cameraModal(camera_number, camera_description) {
        return (
            <div style={{fontSize: '1.0vw'}}>
                <Trans i18nKey="map.elements.camera.number" values={{ camera_number: camera_number}}>
                    <span style={{fontWeight: 'bold', fontSize: 'inherit'}}>Camera</span>: camera_number
                </Trans>
                <br />
                <Trans i18nKey="map.elements.camera.description" values={{ camera_description: camera_description}}>
                <span style={{fontWeight: 'bold', fontSize: 'inherit'}}>Description</span>: camera_description
                </Trans>
            </div>
        );
    }

    _eventModal(event_name, event_time, event_acc_1h) {
        return (
            <>
                <Trans i18nKey="map.elements.event.name" values={{ event_name: event_name}}>
                    <strong>Event</strong>: event_name
                </Trans>
                <br />
                <Trans i18nKey="map.elements.event.time" values={{ event_time: Math.round(event_time)}}>
                    <strong>Time</strong>: event_time
                </Trans>
                <br />
                {/* <Trans i18nKey="map.elements.event.acc1h" values={{ event_acc_1h: event_acc_1h}}>
                    <strong>Acc1h</strong>: event_acc_1h
                </Trans> */}
            </>
        );
    }

    useCameraIcon(feature, latlng) {
        return new L.Marker(latlng, {
            icon: camera_icon
        });
    }

    useStationIcon = (feature, latlng) => {
        let type = feature.properties.TIPO;
        let icon = station_icon;
        if (type.includes('Sirene')) {
            icon = siren_icon;
            if (!this.state.isSirenesShow){
                return null
            }  
        }
        else if (type.includes('FLU')) {
            icon = flu_icon;
            if (!this.state.isFluviometrosShow){
                return null
            }
        } else if (type.includes('PLU')) {
            if (!this.state.isPluviometrosShow){
                return null
            }
        }

        return new L.Marker(latlng, {
            icon: icon
        });
    }

    useApoioIcon(feature, latlng) {
        return new L.Marker(latlng, {
            icon: apoio_icon
        });
    }

    setSubbaciasName(feature, layer) {
        // let latlng = layer.getBounds().getCenter();
        // let label = new L.tooltip();
        // label.setContent(feature.properties.Nome);
        // label.setLatLng(latlng);
        // return label
        if (feature.properties && feature.properties.Nome) {
            // layer.bindPopup(feature.properties.Nome);
            layer.bindTooltip(
                "<div style='font-size: 1.5rem; background:'none'; padding:1px 3px 1px 3px'>"+feature.properties.Nome+"</div>",
                {
                    permanent: false, 
                    direction:"center",
                    opacity: 0.8
                }
            );
        }
        if (feature.properties.id) {
            segmentsById[feature.properties.id] = layer;
        }
    }

    setBairrosName(feature, layer) {
        if (feature.properties && feature.properties.bairro) {
            let bairro_name = feature.properties.bairro;
            layer.bindTooltip(
                "<div style='font-size: 1.5rem; background:'none'; padding:1px 3px 1px 3px'>"+bairro_name+"</div>",
                {
                    permanent: false, 
                    direction:"center",
                    opacity: 1
                }
            );
        }
    }

    setDistritosName(feature, layer) {
        if (feature.properties && feature.properties.distrito) {
            let name = feature.properties.distrito;
            layer.bindTooltip(
                "<div style='font-size: 1.5rem; background:'none'; padding:1px 3px 1px 3px'>"+name+"</div>",
                {
                    permanent: false, 
                    direction:"center",
                    opacity: 0.8
                }
            );
        }

        if (feature.properties.id) {
            segmentsById[feature.properties.id] = layer;
        }
    }

    _useFloodIcon(feature, latlng) {
        let flood_level = FLOOD_CLASS[feature.properties.flood_class];
        let flood_risk = getFloodRisk(feature.properties.risk_proba);
        let flood_icon = getFloodIcon(flood_level, flood_risk);
        if (flood_risk != 'low') {
            return new L.Marker(latlng, {
                icon: flood_icon
            });
        } else {
            return null
        }
    }

    _getBounds(segment_id) {
        if (segment_id == RESET_VIEW_CMD) {
            return actual_bounds
        } else {
            try {
                return segmentsById[segment_id].getBounds()
            }
            catch(err) {
                return DEFAULT_BOUNDS
            }
        }
    }

    toggleMenu = event => {
        event.preventDefault();
        this.setState({
            showMenu: !this.state.showMenu,
        });
    }

    handleClickOutside = event => {
        event.preventDefault();
        if (this.dropdownMenu && !this.dropdownMenu.contains(event.target)){
            this.setState({
                showMenu: false
            });
        }
    }

    toggleRadarLayer = () => {
        this.setState({
            isRadarShow: !this.state.isRadarShow
        })
    }

    handleChangeVision = event => {
        let vision = (this.state.base_layer_type == 'distritos') ? 'microbacias' : 'distritos';
        this.sendData(vision);
        this.setState({base_layer_type: vision})
    }

    renderDropDown = () => {
        const { t } = this.props;
        const isRadarDataReady = (this.state.radarData != null);
        const didCrossLevelThreshold = this.state.isCameraShow;
        const radar_label = !this.state.isRadarShow ? 'Mostrar Radar' : 'Ocultar Radar';
        const pontos_apoio_label = !this.state.isApoiosShow ? 'Mostrar Pontos de Apoio' : 'Ocultar Pontos de Apoio';
        const occurrences_label = !this.state.isOccurrencesShow ? 'Mostrar Ocorrências' : 'Ocultar Ocorrências';
        const pluviometros_label = !this.state.isPluviometrosShow ? 'Mostrar Pluviômetros' : 'Ocultar Pluviômetros';
        const fluviometros_label = !this.state.isFluviometrosShow ? 'Mostrar Fluviômetros' : 'Ocultar Fluviômetros';
        const sirenes_label = !this.state.isSirenesShow ? 'Mostrar Sirenes' : 'Ocultar Sirenes';
        const base_label = 'Trocar para Visão ' + ((this.state.base_layer_type != 'distritos') ? 'Operacional' : 'Ambiental');
        return (
            <Row className="" style={{position: 'absolute', top: '5%', right: '5%', backgroundColor: '#1f2727', borderRadius: '5px', minWidth: '100px', boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)', zIndex: 100, fontSize: '1.3rem'}}>
                <Col>
                    <Row>
                        <Col md={12} className="dropdown-option p-4 cursor-pointer dot-menu-item" onClick={e => this.exportMap(e)}>{t('dashboard.dropdown.export_map')}</Col>
                    </Row>
                    <Row>
                        <Col md={12} className='p-0'><hr className='my-0' /></Col>
                    </Row>
                    <Row>
                        <Col md={12} className="dropdown-option p-4 cursor-pointer dot-menu-item" onClick={e => this.copyMap(e)}>{t('dashboard.dropdown.copy_map')}</Col>
                    </Row>
                    <Row>
                        <Col md={12} className='p-0'><hr className='my-0' /></Col>
                    </Row>
                    <Row>
                        <Col md={12} className="dropdown-option p-4 cursor-pointer dot-menu-item" onClick={e => this.handleChangeVision(e)}>{base_label}</Col>
                    </Row>
                    <Row>
                        <Col md={12} className='p-0'><hr className='my-0' /></Col>
                    </Row>
                    {isRadarDataReady &&
                        <Row>
                            <Col md={12} className="dropdown-option p-4 cursor-pointer dot-menu-item" onClick={e => this.toggleRadarLayer(e)}>{radar_label}</Col>
                        </Row>
                    }
                    <Row>
                        <Col md={12} className='p-0'><hr className='my-0' /></Col>
                    </Row>
                    <Row>
                        <Col md={12} className="dropdown-option p-4 cursor-pointer dot-menu-item" onClick={e => this.setState({isOccurrencesShow: !this.state.isOccurrencesShow})}>{occurrences_label}</Col>
                    </Row>
                    {didCrossLevelThreshold &&
                        <>
                            <Row>
                                <Col md={12} className="dropdown-option p-4 cursor-pointer dot-menu-item" onClick={e => this.setState({isApoiosShow: !this.state.isApoiosShow})}>{pontos_apoio_label}</Col>
                            </Row>
                            <Row>
                                <Col md={12} className='p-0'><hr className='my-0' /></Col>
                            </Row>
                            <Row>
                                <Col md={12} className="dropdown-option p-4 cursor-pointer dot-menu-item" onClick={e => this.setState({isSirenesShow: !this.state.isSirenesShow})}>{sirenes_label}</Col>
                            </Row>
                            <Row>
                                <Col md={12} className='p-0'><hr className='my-0' /></Col>
                            </Row>
                            <Row>
                                <Col md={12} className="dropdown-option p-4 cursor-pointer dot-menu-item" onClick={e => this.setState({isPluviometrosShow: !this.state.isPluviometrosShow})}>{pluviometros_label}</Col>
                            </Row>
                            <Row>
                                <Col md={12} className='p-0'><hr className='my-0' /></Col>
                            </Row>
                            <Row>
                                <Col md={12} className="dropdown-option p-4 cursor-pointer dot-menu-item" onClick={e => this.setState({isFluviometrosShow: !this.state.isFluviometrosShow})}>{fluviometros_label}</Col>
                            </Row>
                        </>
                    }
                </Col>
            </Row>
        )
    }

    render() {
        const { t } = this.props;
        const style_type = this.props.style;
        let style = style_type == 'videowall' ? TILELAYER_TEMPLATE_VIDEOWALL : TILELAYER_TEMPLATE;
        let isLoading = this.state.isDataLoading || this.state.isTileLoading;
        let noFloodOption = style_type == 'noflood';
        let events = this.state.events
        let timestamp = this.state.timestamp;
        let date = new Date(timestamp*1000).toLocaleDateString();
        let time = new Date(timestamp*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
        let cameras_layer = this.state.cameras_layer;
        let isRadarDataReady = (this.state.radarData != null) && this.state.isRadarShow;
        let isMicrobacias = (this.state.base_layer_type == 'distritos') ? false : true;
        return (
            <span id="map">
                <Row className='align-items-center py-2'>
                    <Col md={6} style={{padding: '10px 0 0 0', textAlign: 'right', fontSize: '1.8rem', fontWeight: 'bold'}}>
                        {t('map.title')} |&nbsp;
                    </Col>
                    <Col md={4} style={{padding: '10px 0 0 0', textAlign: 'left', fontSize: '1.6rem'}}>
                        {
                            isLoading ?
                                <SkeletonTheme color="#4c5959" highlightColor="#374141">
                                    <p>
                                        <Skeleton width={300} height={20}/>
                                    </p>
                                </SkeletonTheme>
                            :
                                t('map.subtitle', {date: date, time: time})
                        }
                    </Col>
                    <Col md={2} className='cursor-pointer' style={{textAlign: 'center'}} id='change_map_vision' ref={(e) => {this.dropdownMenu = e}} onClick={this.toggleMenu}>
                        <FontAwesomeIcon icon={faEllipsisV} size="xs" />
                    </Col>
                </Row>
                {this.state.showMenu ? this.renderDropDown() : null}
                <Row className='align-items-center'>
                    <Col md className='p-0'>
                        <hr className='mb-0' />
                    </Col>
                </Row>
                <Row className='' style={{height: '45%'}}>
                    <Col className='p-0' style={{position: 'relative'}}>
                        <Suspense fallback={(<Skeleton />)}>
                            <div style={{display: !isLoading && 'none', zIndex: 2, position: 'absolute', left: '50%', height: '100%', backgroundColor: '#353535'}}>
                                <img src={simple_logo} className="spinner" />
                            </div>
                            <Map id='map_container' key={timestamp} bounds={this.state.bounds} ref={"map"} maxZoom={19} onZoom={this.handleZoom} preferCanvas={true} style={{zIndex: 3, position: 'absolute'}} >
                                <TileLayer url={style.url} attribution={style.attribution} onLoad={() => {this.setState({isTileLoading: false})}}/>

                                { 
                                    !isLoading && 
                                    <>
                                    
                                        {isMicrobacias &&
                                            <GeoJSON key={hash(this.state.mobilityData)} ref="subbaciasLayer" data={this.state.mobilityData} style={this.style_subbacia} onEachFeature={this.setSubbaciasName}/>
                                        }

                                        {(!isMicrobacias && !this.state.isCameraShow) &&
                                            <GeoJSON key={hash(this.state.districts)} ref="distritos" data={this.state.districts} style={this.style_subbacia} onEachFeature={this.setDistritosName}/>
                                        }

                                        {/* <GeoJSON key={'mobilityLayer_' + hash(this.state.mobilityData)} ref="mobilityLayer" data={this.state.mobilityData} style={this.style_subbacia} onEachFeature={this.onEachSegmentFeature} pointToLayer={(feature, latlng) => new L.Marker(latlng, {})} /> */}
                                        
                                        { (this.state.isOccurrencesShow) &&
                                            // <GeoJSON key={'ocorrencias'} ref="warningLayer" data={this.state.events} pointToLayer={(feature, latlng) => this.create_cluster(latlng)} />
                                            this.create_cluster(this.state.events)
                                            // <MarkerClusterGroup>
                                            //     <Marker position={[-22.5224965, -43.1901612]} />
                                            //     <Marker position={[-22.529021,-43.196369]} />
                                            // </MarkerClusterGroup>
                                        }

                                        {
                                            !noFloodOption && 
                                            <GeoJSON key={'floodData_' + hash(this.state.floodData)} data={this.state.floodData} onEachFeature={this.onEachFloodFeature} pointToLayer={this._useFloodIcon} />
                                        }
                                    </>
                                }
                                
                                { (this.state.isCameraShow && isMicrobacias) && 
                                    // <GeoJSON key={'cameraLayer_' + hash(cameras_layer)} data={cameras_layer} onEachFeature={this.onEachCameraFeature} pointToLayer={this.useCameraIcon} />
                                    <GeoJSON key={hash(this.state.riskAreas)} data={this.state.riskAreas} onEachFeature={this.onEachSegmentFeature}  style={this.styleRisco}/>
                                }
                                { (this.state.isCameraShow && !isMicrobacias) && //TODO tem que atualizar o estado de this.state.isCameraShow para true
                                    <GeoJSON key={hash(this.state.neighborhoods)} data={this.state.neighborhoods} onEachFeature={this.onEachSegmentFeature}  style={this.styleRisco}/>
                                }

                                { this.state.isCameraShow && 
                                    <GeoJSON key={this.state.isPluviometrosShow << 2 | this.state.isFluviometrosShow << 1 | this.state.isSirenesShow} ref={'stations'} data={estacoes} onEachFeature={this.onEachStationFeature} pointToLayer={this.useStationIcon} />
                                }

                                { (this.state.isCameraShow && this.state.isApoiosShow) &&
                                    <GeoJSON key={hash(pontos_apoio)} data={pontos_apoio} onEachFeature={this.onEachApoioFeature} pointToLayer={this.useApoioIcon} />
                                }

                                {/* <Control key={'control_buttons'} position="topright" >
                                    <Button key={'reset_button'} variant="resetview" onClick={() => this.goToPoint(DEFAULT_BOUNDS)}> {t('map.button.reset_view')} </Button>
                                </Control> */}

                                {isRadarDataReady &&
                                    <ImageOverlay key='radar' url = {URL_GIF} bounds={this.state.radarData.bounds} opacity={0.6}/>
                                }

                            </Map>
                        </Suspense>
                    </Col>
                </Row>
            </span>
        );
    }
}

export default withTranslation()(MapWrap);